import { IOpsPortalAggregateAgencyAssignments } from '@schemas/opsPortal/types/aggregateAgencyAssignments';

export const getUniqueDates = (
  data: IOpsPortalAggregateAgencyAssignments,
): string[] => {
  const dateSet = new Set<string>();

  Object.values(data).forEach(agencyDates => {
    Object.keys(agencyDates).forEach(date => dateSet.add(date));
  });

  return Array.from(dateSet).sort((a, b) => a.localeCompare(b));
};
