/* eslint-disable max-len, max-lines */
import { useCallback } from 'react';
import { useSearchParams } from 'react-router-dom';
import { AxiosError } from 'axios';
import { GridColDef, GridColumnVisibilityModel } from '@mui/x-data-grid';
import { DataGrid } from '@components/Common.styles';
import { Filters } from '@components/Filters/Filters';
import { Toggle } from '@components/Toggle';
import { ITableSortingSettings } from '@hooks/useTableSorting/types';
import { NoRowsOverlayCustom } from './components/NoRowsOverlayCustom';
import { TableType } from './enums';
import { defaultPageSizeOptions } from './helpers/constants';
import { useFilterChangeHandler } from './hooks/useFilterChangeHandler';
import { useLimitOffsetPaginationChangeHandler } from './hooks/useLimitOffsetPaginationChangeHandler';
import { useSyncSortSearchParamsEffect } from './hooks/useSyncSortSearchParamsEffect';
import { useSyncURLParamsLimitOffset } from './hooks/useSyncURLParamsLimitOffset';
import { TableWrapper } from './TableWrapper';
import {
  IFilterSettings,
  IPaginationSettings,
  IRowSelectionSettings,
  IToggleSettings,
  LimitOffsetPaginationModel,
} from './types';
import { processTableData } from './utils';

interface ITableLimitOffsetV2<TFilterValues> {
  columnVisibility?: GridColumnVisibilityModel;
  columns: GridColDef[]; // object of fields with boolean values, default to true (visible)
  count: number;
  data: object[];
  error?: AxiosError | null;
  filterSettings?: IFilterSettings<TFilterValues>;
  isInTab?: boolean;
  isLoading?: boolean;
  isStatic?: boolean;
  minWidth?: string;
  name: string;
  paginationSettings: IPaginationSettings<LimitOffsetPaginationModel>;
  rowSelectionSettings?: IRowSelectionSettings;
  sortingSettings?: ITableSortingSettings;
  title: string;
  toggleSettings?: IToggleSettings;
  wrapTableInCard?: boolean;
}

export const TableLimitOffsetV2 = <TFilterValues,>({
  columns = [],
  columnVisibility = {},
  count,
  data = [],
  error,
  filterSettings,
  isInTab = false,
  isLoading,
  isStatic = false,
  minWidth,
  name,
  paginationSettings,
  rowSelectionSettings,
  sortingSettings,
  title,
  toggleSettings,
  wrapTableInCard = true,
}: ITableLimitOffsetV2<TFilterValues>) => {
  const [searchParams, setSearchParams] = useSearchParams(
    new URLSearchParams(window.location.search),
  );

  const {
    handleSwitch,
    label: toggleLabel,
    value: toggleValue,
  } = toggleSettings ?? {};

  const processedData = processTableData(data);

  const {
    handlePagination,
    pageNumber,
    pageSize,
    pageSizeOptions = defaultPageSizeOptions,
    paginationParams,
  } = paginationSettings;

  const {
    filterHandler,
    filters,
    filterValues,
    initialDefaultValues,
    resetFiltersHandler: resetFilters,
    showClearFiltersButton = true,
  } = filterSettings ?? {};
  const { allowRowSelection = false, handleRowSelection } =
    rowSelectionSettings ?? {};
  const { handleSortModelChange, sortModel, sortSearchParam } =
    sortingSettings ?? {};

  useSyncURLParamsLimitOffset({
    searchParams,
    filterHandler,
    handleSortModelChange,
    handlePagination,
  });

  useSyncSortSearchParamsEffect({
    searchParams,
    setSearchParams,
    sortSearchParam,
  });

  const handleFilterChange = useFilterChangeHandler<TFilterValues>({
    filterHandler,
    filterValues,
    paginationParams,
    setSearchParams,
    sortSearchParam,
  });

  const handleLimitOffsetPaginationChange =
    useLimitOffsetPaginationChangeHandler<TFilterValues>({
      filterValues,
      handlePagination,
      setSearchParams,
      sortSearchParam,
      paginationParams,
    });

  const handleFilterReset = useCallback(() => {
    handleLimitOffsetPaginationChange({ page: 0, pageSize });
    resetFilters?.();
    handleSortModelChange?.([]);
    setSearchParams(initialDefaultValues ?? {});
  }, [
    handleLimitOffsetPaginationChange,
    handleSortModelChange,
    initialDefaultValues,
    pageSize,
    resetFilters,
    setSearchParams,
  ]);

  return (
    <TableWrapper
      error={error}
      isLoading={isLoading}
      title={title}
      wrapTableInCard={wrapTableInCard}
    >
      {filters ? (
        <Filters
          filterHandler={handleFilterChange}
          filters={filters}
          isInTab={isInTab}
          resetFilters={handleFilterReset}
          showClearFiltersButton={showClearFiltersButton}
        />
      ) : null}
      {handleSwitch && toggleValue !== undefined ? (
        <Toggle
          handleSwitch={handleSwitch}
          isOn={toggleValue}
          label={toggleLabel}
        />
      ) : null}
      <DataGrid
        aria-label={name}
        autoHeight={true}
        checkboxSelection={allowRowSelection}
        clipboardCopyCellDelimiter=","
        columnHeaderHeight={56}
        columns={columns}
        disableColumnMenu={true}
        disableRowSelectionOnClick={!allowRowSelection}
        initialState={{
          columns: {
            columnVisibilityModel: columnVisibility,
          },
        }}
        minWidth={minWidth}
        onPaginationModelChange={handleLimitOffsetPaginationChange}
        onRowSelectionModelChange={handleRowSelection}
        onSortModelChange={handleSortModelChange}
        {...(!isStatic
          ? {
              pagination: true,
              paginationMode: TableType.SERVER,
              pageSizeOptions,
              paginationModel: { page: pageNumber, pageSize },
              rowCount: count,
            }
          : {
              paginationModel: { page: 0, pageSize: count },
              sx: {
                '& .MuiTablePagination-root': {
                  display: 'none',
                },
              },
            })}
        rowHeight={40}
        rows={processedData}
        slots={{
          noRowsOverlay: NoRowsOverlayCustom,
        }}
        sortModel={sortModel}
      />
    </TableWrapper>
  );
};
