import React from 'react';
import { Typography, styled } from '@mui/material';
import { missingValuePlaceholder } from '@utils/constants';

type DetailItemProps = {
  component?: React.ReactNode;
  label: string;
  value?: string | number | null;
};

const StyledDt = styled('dt')(({ theme }) => ({
  ...theme.typography.body2,
  fontWeight: 600,
  display: 'inline',
  marginRight: theme.spacing(1),
}));

const StyledDd = styled('dd')(({ theme }) => ({
  ...theme.typography.body2,
  margin: 0,
  display: 'inline',
}));

export const DetailItem = ({ component, label, value }: DetailItemProps) => (
  <>
    <StyledDt>{label}</StyledDt>
    <StyledDd>
      {component || (
        <Typography component="span" variant="body2">
          {value ?? missingValuePlaceholder}
        </Typography>
      )}
    </StyledDd>
  </>
);

export default DetailItem;
