import { useMemo, useState } from 'react';
import { AxiosError } from 'axios';
import { useQuery } from '@tanstack/react-query';
import { getDatesToFromToday } from '@components/TableV2/helpers/getDatesToFromToday';
import { formatQueryParams } from '@components/TableV2/helpers/utils';
import {
  ITableConfig,
  IToggleSettings,
  LimitOffsetPaginationModel,
} from '@components/TableV2/types';
import { Translate } from '@hooks/useI18n';
import { useLimitOffsetPagination } from '@hooks/useModal/hooks/useLimitOffsetPagination';
import { useTableSorting } from '@hooks/useTableSorting/useTableSorting';
import { IOpsPortalAggregateAgencyAssignments } from '@schemas/opsPortal/types/aggregateAgencyAssignments';
import { QueryKeys } from '@utils/constants';
import { Endpoints } from '@utils/enums';
import { fetchDataFromEndpoint } from '@utils/fetchDataFromEndpoint/fetchDataFromEndpoint';
import { defaultAggregateAgencyAssignments } from './constants';
import { IAgencyAssignmentsFilterValues } from './types';
import { useGetAgencyAssignmentsByAgencyBucketDays } from './useGetAgencyAssignmentsByAgencyBucketDays';
import { useGetAgencyAssignmentsByAgencyBucketWeeks } from './useGetAgencyAssignmentsByAgencyBucketWeeks';
import { useGetAgencyAssignmentsColumnsDays } from './useGetAgencyAssignmentsColumnsDays';
import { useGetAgencyAssignmentsColumnsWeeks } from './useGetAgencyAssignmentsColumnsWeeks';
import { useGetAgencyAssignmentsFilters } from './useGetAgencyAssignmentsFilters';
import { getUniqueDates } from './utils/getUniqueDates';

interface IUseGetAgencyAssignmentsTableConfig {
  translate: Translate;
}

interface IAgencyAssigmentsTableConfig
  extends ITableConfig<
    IAgencyAssignmentsFilterValues,
    LimitOffsetPaginationModel,
    IOpsPortalAggregateAgencyAssignments
  > {
  hasDates: boolean;
}

export const useGetAgencyAssignmentsTableConfig = ({
  translate,
}: IUseGetAgencyAssignmentsTableConfig): IAgencyAssigmentsTableConfig => {
  const [breakdownByAgency, setBreakdownByAgency] = useState(false);
  const handleSwitch = () => {
    setBreakdownByAgency(prev => !prev);
  };

  const toggleSettings: IToggleSettings = {
    handleSwitch,
    value: breakdownByAgency,
    label: translate('OPS_PORTAL.LABEL.BREAKDOWN_BY_AGENCY'),
  };

  const sortingSettings = useTableSorting();
  const paginationSettings = useLimitOffsetPagination();

  const { from, to } = getDatesToFromToday(7);
  const initialDefaultFilterValues: IAgencyAssignmentsFilterValues = {
    assignmentStartDateFrom: from,
    assignmentStartDateTo: to,
  };

  const filtersSettings = useGetAgencyAssignmentsFilters({
    initialDefaultValues: initialDefaultFilterValues,
    translate,
  });

  const {
    filterValues: { assignmentStartDateFrom, assignmentStartDateTo },
  } = filtersSettings;

  const stringifiedQueryParams = useMemo(
    () => formatQueryParams(filtersSettings.filterValues as object),
    [filtersSettings.filterValues],
  );

  const queryKey = useMemo(
    () => [QueryKeys.AGENCY_ASSIGMENTS, stringifiedQueryParams],
    [stringifiedQueryParams],
  );

  const {
    data: agencyAssignmentData,
    error,
    isLoading,
  } = useQuery<IOpsPortalAggregateAgencyAssignments, AxiosError>(queryKey, () =>
    fetchDataFromEndpoint(Endpoints.FetchAgencyAssignmentsAggregated, {
      query: {
        assignmentStartDateFrom: assignmentStartDateFrom ?? '',
        assignmentStartDateTo: assignmentStartDateTo ?? '',
      },
    }),
  );

  const uniqueAssignmentDates = useMemo(
    () =>
      getUniqueDates(agencyAssignmentData ?? defaultAggregateAgencyAssignments),
    [agencyAssignmentData],
  );

  const columnsByDay = useGetAgencyAssignmentsColumnsDays({
    dates: uniqueAssignmentDates,
    translate,
  });

  const columnsByWeek = useGetAgencyAssignmentsColumnsWeeks({
    dates: uniqueAssignmentDates,
    translate,
  });

  const columnsByAgencyByDay = useGetAgencyAssignmentsByAgencyBucketDays({
    dates: uniqueAssignmentDates,
    translate,
  });

  const columnsByAgencyByWeeks = useGetAgencyAssignmentsByAgencyBucketWeeks({
    dates: uniqueAssignmentDates,
    translate,
  });

  const columnsByBucketOnly = useMemo(
    () => (uniqueAssignmentDates.length > 10 ? columnsByWeek : columnsByDay),
    [uniqueAssignmentDates, columnsByWeek, columnsByDay],
  );

  const columnsBrokenDownByAgency = useMemo(
    () =>
      uniqueAssignmentDates.length > 10
        ? columnsByAgencyByWeeks
        : columnsByAgencyByDay,
    [uniqueAssignmentDates, columnsByAgencyByWeeks, columnsByAgencyByDay],
  );

  return {
    columns: toggleSettings?.value
      ? columnsBrokenDownByAgency
      : columnsByBucketOnly,
    data: agencyAssignmentData ?? defaultAggregateAgencyAssignments,
    hasDates: !!uniqueAssignmentDates.length,
    error,
    filtersSettings,
    isLoading,
    paginationSettings,
    sortingSettings,
    toggleSettings,
  };
};
