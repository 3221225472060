import { Link } from 'react-router-dom';
import { ToCamelCase } from '@heidi-pay/heidi-common-fe/types';
import { addPlaceholderForEmptyValues } from '@heidi-pay/heidi-common-fe/utils';
import { useI18n } from '@hooks/useI18n';
import { IContractDetails } from '@schemas/opsPortal/types/contractDetails';
import { getFullName } from '@utils/getFullName';
import { ICustomerData } from './types';

interface ICustomerSectionProps {
  contractDetails?: ToCamelCase<IContractDetails>;
}

export const useCustomerSection = ({
  contractDetails,
}: ICustomerSectionProps) => {
  const { getLocaleDate } = useI18n();

  const {
    consumerDetails,
    consumerUuid,
    formattedBillingAddress,
    formattedShippingAddress,
    language,
  } = contractDetails ?? {};

  const { dateOfBirth, email, firstName, lastName, phoneNumber } =
    consumerDetails ?? {};

  const data = addPlaceholderForEmptyValues<ICustomerData>({
    consumerUuid: consumerUuid ? (
      <Link to={`/consumers/${consumerUuid}`}>{consumerUuid}</Link>
    ) : null,
    customerName: getFullName({ firstName, lastName }),
    email,
    address: formattedShippingAddress || formattedBillingAddress,
    phone: phoneNumber,
    language,
    dateOfBirth: dateOfBirth && getLocaleDate({ date: dateOfBirth }),
  });

  const customerSection = [
    {
      label: 'OPS_PORTAL.LABEL.CUSTOMER_UUID',
      component: data.consumerUuid,
    },
    {
      label: 'TITLE.CUSTOMER_NAME',
      value: data.customerName,
    },
    {
      label: 'LABEL.EMAIL_ADDRESS',
      value: data.email,
    },
    {
      label: 'LABEL.ADDRESS',
      value: data.address,
    },
    {
      label: 'LABEL.PHONE_NUMBER',
      value: data.phone,
    },
    {
      label: 'LABEL.LANGUAGE',
      value: data.language,
    },
    {
      label: 'OPS_PORTAL.LABEL.DATE_OF_BIRTH',
      value: data.dateOfBirth,
    },
  ];

  return { customerSection };
};
