/* eslint-disable max-lines */
/* eslint-disable react/jsx-no-bind */
import { useCallback, useEffect, useMemo, useRef } from 'react';
import { toSnakeCase } from '@heidi-pay/heidi-common-fe/utils';
import { IApplicationsTableData } from '@appTypes';
import { Spinner } from '@auth/pages/Auth.styles';
import { Card } from '@components/Card';
import { CSVLink } from '@components/Common.styles';
import { NoDataAvailable } from '@components/NoDataAvailable';
import { QueryStateRouter } from '@components/QueryStateRouter';
import { ServersideTable } from '@components/Table/ServersideTable';
import { ITableRefObject } from '@components/Table/types';
import { useGetMerchantsQuery } from '@hooks/queries';
import { useHasPermission } from '@hooks/useHasPermission';
import { useI18n } from '@hooks/useI18n/useI18n';
import { useMerchantSettingGroup } from '@hooks/useMerchantSettingGroup';
import { useOriginator } from '@hooks/useOriginator';
import { usePortal } from '@hooks/usePortal';
import { useQueryState } from '@hooks/useQueryState';
import { IApplicationPageOriginationChannelType } from '@schemas/merchantPortal/types/accountSettings';
import { TableNames } from '@utils/constants';
import { downloadCSV } from '@utils/downloadCSV';
import { OriginationChannel } from '@utils/enums';
import { getApplicationsTableColumns } from '@utils/getApplicationsTableColumns';
import { getCursorPaginationCallbacks } from '@utils/getCursorPaginationCallbacks';
import { getLocalTimezone } from '@utils/getLocalTimezone';
import { getMerchantsOptions } from '@utils/getMerchantsOptions';
import { mapApplicationsTableData } from '@utils/mapApplicationsTableData';
import { createApplicationsCSV } from './createApplicationsCSV';
import {
  useGetApplicationsCsvQuery,
  useGetApplicationsQuery,
  useGetExperianIncomeDocumentReportCsv,
} from './queries';
import { ICsvColumnConfig } from './types';

interface IApplications {
  isMyApplications?: boolean;
}

const getMerchantPortalOriginationChannel = (
  isCompass: boolean,
  applicationChannelType: IApplicationPageOriginationChannelType | undefined,
) => {
  if (applicationChannelType === 'both' || isCompass) {
    return '';
  }

  return applicationChannelType || OriginationChannel.InStore;
};

export const Applications = ({ isMyApplications = false }: IApplications) => {
  const { isMerchantPortal, isOpsPortal, portal } = usePortal();
  const {
    getLocaleCurrencyValue,
    getLocaleDate,
    selectedLanguage: lang,
    translate,
  } = useI18n();
  const { applicationChannelType, getMerchantSetting } =
    useMerchantSettingGroup();
  const merchantColumnConfig = getMerchantSetting('ApplicationPageColumns');

  const { isCompass, isPagolightPro, originator } = useOriginator();
  const tableName = TableNames.APPLICATIONS;

  const originationChannel = isMerchantPortal
    ? getMerchantPortalOriginationChannel(isCompass, applicationChannelType)
    : '';

  const {
    activateQueryStateHook,
    getFilters,
    queryParams,
    queryState,
    resetFilters,
    updateQueryParams,
  } = useQueryState(tableName);

  const { isMerchantsLoading, merchantsData, merchantsError } =
    useGetMerchantsQuery({ originator, enabled: isOpsPortal });

  const customQueryParams = {
    ...queryParams,
    originator,
    originationChannel,
    isMerchantPortal: isMerchantPortal.toString(),
    isMyApplications: isMyApplications.toString(),
    lang,
    timezone: getLocalTimezone(),
  };

  const { applicationsData, applicationsError, isApplicationsLoading } =
    useGetApplicationsQuery(customQueryParams);

  const merchantsOptions = useMemo(
    () => getMerchantsOptions(merchantsData),
    [merchantsData],
  );

  const filters = getFilters({
    merchantsOptions,
    isMerchantPortal,
    isOpsPortal,
  });

  const columns = useMemo(
    () =>
      getApplicationsTableColumns({
        isCompass,
        translate,
        isPagolightPro,
        merchantColumnConfig,
        portal,
      }),
    [isCompass, translate, isPagolightPro, merchantColumnConfig, portal],
  );

  const columnConfig: ICsvColumnConfig[] = columns.map(column => ({
    key: toSnakeCase(column.accessorKey),
    title: column.header,
  }));

  const { downloadCsv, isLoadingCsv } = useGetApplicationsCsvQuery({
    queryParams: customQueryParams,
    columnConfig,
  });

  const {
    downloadCsv: downloadIncomeDocumentReportCsv,
    isLoadingCsv: isLoadingIncomeDocumentReportCsv,
  } = useGetExperianIncomeDocumentReportCsv({
    queryParams: customQueryParams,
  });
  const canDownloadExperianIncomeDocumentReportCsv = useHasPermission([
    'ops_portal.can_download_experian_income_document_report',
  ]);
  const experianIncomeDocumentReportButton =
    canDownloadExperianIncomeDocumentReportCsv
      ? {
          ...(isLoadingIncomeDocumentReportCsv
            ? {
                additionalButtonContent: <Spinner extraClassName="pr-5" />,
              }
            : {}),
          clickHandler: downloadIncomeDocumentReportCsv,
          buttonKey: 'downloadIncomeDocumentReportCSV',
          text: translate(
            'OPS_PORTAL.BUTTON.DOWNLOAD_EXPERIAN_INCOME_DOCUMENT_REPORT_CSV',
          ),
        }
      : undefined;

  const tableData =
    applicationsData &&
    mapApplicationsTableData({
      data: applicationsData.results,
      getLocaleCurrencyValue,
      getLocaleDate,
      isMerchantPortal,
      translate,
    });

  const refToCSVLink = useRef<HTMLAnchorElement>(null);
  const refToDataTable = useRef<ITableRefObject<IApplicationsTableData>>(null);

  const handleDownloadClick = useCallback(() => {
    if (refToDataTable.current) {
      const { columns: cols, rows } = refToDataTable.current;
      const csv = createApplicationsCSV({
        columns: cols,
        data: rows,
        translate,
      });
      downloadCSV({ csv, refToCSVLink });
    }
  }, [translate]);

  const csvButtonText = translate('BUTTON.DOWNLOAD_APPLICATIONS');

  const isLoading =
    isApplicationsLoading || (isOpsPortal && isMerchantsLoading);

  const getOpsButtons = () =>
    isOpsPortal
      ? [
          {
            clickHandler: handleDownloadClick,
            buttonKey: 'downloadCSV',
            text: csvButtonText,
          },
          ...(experianIncomeDocumentReportButton
            ? [experianIncomeDocumentReportButton]
            : []),
        ]
      : [];

  const getMerchantButtons = () =>
    isMerchantPortal
      ? [
          {
            ...(isLoadingCsv
              ? {
                  additionalButtonContent: <Spinner extraClassName="pr-5" />,
                }
              : {}),
            clickHandler: downloadCsv,
            buttonKey: 'downloadCSV',
            text: csvButtonText,
          },
        ]
      : [];

  useEffect(() => {
    activateQueryStateHook();
  }, [activateQueryStateHook]);

  const { gotoNextPage, gotoPrevPage } = getCursorPaginationCallbacks({
    nextCursor: applicationsData?.next_cursor,
    prevCursor: applicationsData?.previous_cursor,
    queryState,
    updateQueryParams,
  });

  return (
    <Card
      buttons={isLoading ? [] : [...getOpsButtons(), ...getMerchantButtons()]}
      title={translate(
        isMyApplications ? 'TITLE.MY_APPLICATIONS' : 'TITLE.APPLICATIONS',
      )}
    >
      <QueryStateRouter
        error={applicationsError || merchantsError}
        isLoading={isLoading}
      >
        {tableData ? (
          <>
            {isOpsPortal ? (
              <CSVLink download={tableName} ref={refToCSVLink} />
            ) : null}
            <ServersideTable
              columns={columns}
              data={tableData}
              filters={filters}
              isCursorPagination={true}
              manageControlledState={updateQueryParams}
              pagination={{
                gotoNextPage,
                gotoPrevPage,
                pageSize: queryParams.pageSize,
                totalItems: applicationsData?.count,
              }}
              ref={refToDataTable}
              resetFilters={resetFilters}
              tableState={queryState}
            />
          </>
        ) : (
          <NoDataAvailable />
        )}
      </QueryStateRouter>
    </Card>
  );
};
