import { useParams } from 'react-router-dom';
import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import { AccessController } from '@components/AccessController';
import { ApiKeys } from '@components/ApiKeys';
import { Card } from '@components/Card';
import { H3 } from '@components/Common.styles';
import { DataDisplay } from '@components/DataDisplay';
import { NoDataAvailable } from '@components/NoDataAvailable';
import { QueryStateRouter } from '@components/QueryStateRouter';
import { useHasPermission } from '@hooks/useHasPermission';
import { useI18n } from '@hooks/useI18n/useI18n';
import { useChangeIbanModal } from '@hooks/useModal/hooks/useChangeIbanModal';
// eslint-disable-next-line max-len
import { useOpsUserMerchantDocumentUploadModal } from '@hooks/useModal/hooks/useOpsUserDocumentUploadModal/useOpsUserDocumentUploadModal';
import { sort } from '@utils/sort';
import { getDataList } from './getDataList';
import { useGetPauseSettlementButton } from './hooks';
import { MerchantDocumentTable } from './MerchantDocumentTable';
import { useGetMerchantDetailsQuery } from './queries';
import { NotesTable } from './tables/merchantNotes/MerchantNotesTable';
import { getOrderProfileData } from './tables/orderProfiles/getOrderProfileData';
import { OrderProfileTable } from './tables/orderProfiles/OrderProfileTable';

const StyledCardlet = styled(Box)({
  border: '1px solid #ddd',
  padding: '20px 15px',
  marginBottom: '30px',
  borderRadius: '5px',
});

const StyledH3 = styled(H3)({
  fontWeight: '700',
  marginBottom: '20px',
});

export const MerchantDetails = () => {
  const { getLocaleDate, translate } = useI18n();
  const { uuid } = useParams();

  const canViewIban = useHasPermission(['ops_portal.view_merchant_iban']);
  const canViewDocuments = useHasPermission([
    'ops_portal.can_view_merchant_documents',
  ]);

  const canViewMerchantNotes = useHasPermission([
    'ops_portal.can_view_merchant_notes',
  ]);

  const {
    dataConfigs,
    dataIban,
    dataMerchantDetails,
    errorMerchantDetails,
    isLoading,
  } = useGetMerchantDetailsQuery({ uuid, canViewIban });

  const dataList =
    dataMerchantDetails &&
    getDataList({
      canViewIban,
      data: dataMerchantDetails,
      getLocaleDate,
      translate,
      ...(canViewIban && dataIban ? { iban: dataIban.iban } : {}),
    });

  const orderProfileGroupDetails =
    dataMerchantDetails &&
    getOrderProfileData({
      data: dataMerchantDetails,
      translate,
    });

  const changeIbanModal = useChangeIbanModal({
    dependencies: [canViewIban],
    iban: dataIban?.iban,
    merchantUuid: dataMerchantDetails?.external_uuid,
  });

  const pauseMerchantSettlementButton = useGetPauseSettlementButton({
    uuid,
    merchantSettlementPaused: dataMerchantDetails?.merchant_settlement_paused,
  });

  const uplaodDocumentModal = useOpsUserMerchantDocumentUploadModal({
    targetUuid: uuid,
  });

  const buttons = [
    ...(pauseMerchantSettlementButton ? [pauseMerchantSettlementButton] : []),
    ...(changeIbanModal ? [changeIbanModal.button] : []),
    ...(uplaodDocumentModal ? [uplaodDocumentModal.button] : []),
  ];

  return (
    <>
      <Card
        buttons={buttons}
        title={translate('OPS_PORTAL.TITLE.MERCHANT_DETAILS')}
      >
        <QueryStateRouter error={errorMerchantDetails} isLoading={isLoading}>
          {dataList ? <DataDisplay dataList={dataList} /> : <NoDataAvailable />}
        </QueryStateRouter>
      </Card>
      <Card title={translate('OPS_PORTAL.TITLE.ORDER_PROFILE_GROUPS')}>
        <QueryStateRouter error={errorMerchantDetails} isLoading={isLoading}>
          <>
            {orderProfileGroupDetails?.orderProfileGroups
              ?.sort((a, b) => sort(a.name ?? '', b.name ?? ''))
              .map(orderProfileGroup => (
                <StyledCardlet key={orderProfileGroup.uuid}>
                  <StyledH3>{orderProfileGroup.name}</StyledH3>
                  <DataDisplay dataList={orderProfileGroup.dataList} />
                  <OrderProfileTable
                    configs={dataConfigs}
                    orderProfiles={orderProfileGroup.orderProfiles}
                  />
                </StyledCardlet>
              ))}
          </>
        </QueryStateRouter>
      </Card>

      {canViewMerchantNotes ? <NotesTable /> : null}

      {canViewDocuments ? (
        <Card title={translate('OPS_PORTAL.TITLE.CONTRACT_DOCUMENTS')}>
          {uuid ? <MerchantDocumentTable merchantUuid={uuid} /> : null}
        </Card>
      ) : null}
      <AccessController scopes={['ops_portal.can_view_merchants_keys']}>
        <ApiKeys />
      </AccessController>
    </>
  );
};
