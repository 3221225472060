import { useCallback } from 'react';
import { AxiosError } from 'axios';
import { GridColumnVisibilityModel, GridColDef } from '@mui/x-data-grid';
import { ActionButtons } from '@appTypes';
import { DataGrid } from '@components/Common.styles';
import { NoRowsOverlayCustom } from './components/NoRowsOverlayCustom';
import { TableType } from './enums';
import { defaultPageSizeOptions } from './helpers/constants';
import { TableWrapper } from './TableWrapper';
import { processTableData } from './utils';

interface IClientTableProps {
  buttons?: ActionButtons;
  columnVisibility?: GridColumnVisibilityModel;
  columns: GridColDef[];
  data: object[];
  error?: AxiosError | null;
  isLoading?: boolean;
  minWidth?: string;
  name: string;
  title?: string;
  wrapTableInCard?: boolean;
}

export const ClientTableV2 = ({
  buttons = [],
  columns = [],
  columnVisibility = {},
  data = [],
  error,
  isLoading,
  minWidth,
  name,
  title,
  wrapTableInCard = true,
}: IClientTableProps) => {
  const getRowHeight = useCallback(() => 'auto', []);
  const processedData = processTableData(data);

  return (
    <TableWrapper
      buttons={buttons}
      error={error}
      isLoading={isLoading}
      title={title}
      wrapTableInCard={wrapTableInCard}
    >
      <DataGrid
        aria-label={name}
        autoHeight={true}
        clipboardCopyCellDelimiter=","
        columnHeaderHeight={56}
        columns={columns}
        disableColumnMenu={true}
        disableRowSelectionOnClick={true}
        getRowHeight={getRowHeight}
        initialState={{
          columns: {
            columnVisibilityModel: columnVisibility,
          },
          pagination: {
            paginationModel: {
              pageSize: 10,
            },
          },
        }}
        minWidth={minWidth}
        pageSizeOptions={defaultPageSizeOptions}
        pagination={true}
        paginationMode={TableType.CLIENT}
        rows={processedData}
        slots={{
          noRowsOverlay: NoRowsOverlayCustom,
        }}
      />
    </TableWrapper>
  );
};
