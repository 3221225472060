import { IOpsPortalConsumerCommunicationEvent } from '@schemas/opsPortal/types/consumerCommunicationEvent';
import { wrapDisplayData } from './wrapDisplayData';

export const getDisplayData = (data: IOpsPortalConsumerCommunicationEvent) => {
  const {
    attachments,
    channel,
    content_sent: content,
    sent_to: to,
    subject,
  } = data;

  if (channel === 'text_message') {
    // Just in case this is flagged by Sonar or PR reviews, we do want
    // ||, not ??, because if the content could be an empty string.
    return wrapDisplayData(`<em>To: ${to}</em><br />${content || subject}`);
  }

  const onClickMessage = (filename: string, mimetype: string) =>
    `{message: 'attachment-download', filename: '${filename}', mimetype: '${mimetype}'}`;
  const onClickText = (filename: string, mimetype: string) =>
    `onClick="window.parent.postMessage(${onClickMessage(
      filename,
      mimetype,
    )});"`;

  const attachmentMarkup = attachments?.length
    ? `Attachments: ${attachments.map(
        attachment =>
          `<a href="javascript:void(0);" ${onClickText(
            attachment.filename,
            attachment.mimetype,
          )}">${attachment.filename}</a> `,
      )}<br />`
    : '';

  if (subject) {
    return wrapDisplayData(
      `<em>To: ${to}<br />Subject: ${subject}</em><br />${attachmentMarkup}${content}`,
    );
  }

  return wrapDisplayData(
    `<em>To: ${to}</em><br />${attachmentMarkup}${content}`,
  );
};
