import { Translate } from '@hooks/useI18n';
import { IOpsPortalBlockExternalCollectionsReferral } from '@schemas/opsPortal/types/blockExternalCollectionsReferral';
import { IOpsPortalPauseCommunications } from '@schemas/opsPortal/types/pauseCommunications';
import { IContractOverview } from './types';

interface IGetContractDetailsFieldNames {
  collectionsBlockAutoReferralData?: IOpsPortalBlockExternalCollectionsReferral;
  isArrearsToBeManagedByHeidi: boolean;
  isCompass: boolean;
  pauseCommunicationsData?: IOpsPortalPauseCommunications;
  translate: Translate;
}

export const getContractOverviewFieldNames = ({
  collectionsBlockAutoReferralData,
  isArrearsToBeManagedByHeidi,
  isCompass,
  pauseCommunicationsData,
  translate,
}: IGetContractDetailsFieldNames) => {
  const contractUUID = translate('OPS_PORTAL.LABEL.CONTRACT_UUID');
  const monthlyPaymentAmount = translate(
    'OPS_PORTAL.LABEL.MONTHLY_PAYMENT_AMOUNT',
  );
  const numberOfInstallments = translate('LABEL.NUMBER_OF_INSTALMENTS');
  const originalNumberOfInstallments = translate(
    'OPS_PORTAL.LABEL.ORIGINAL_NUMBER_OF_INSTALMENTS',
  );

  const contractOverview: IContractOverview = {
    contractUUID,
    merchantName: translate('LABEL.MERCHANT_NAME'),
    merchantUserId: translate('OPS_PORTAL.LABEL.MERCHANT_USER_ID'),
    merchantUserName: translate('OPS_PORTAL.LABEL.MERCHANT_USER_NAME'),
    merchantUserPhoneNumber: translate(
      'OPS_PORTAL.LABEL.MERCHANT_USER_PHONE_NUMBER',
    ),
    merchantOrderReference: translate(
      'OPS_PORTAL.LABEL.MERCHANT_ORDER_REFERENCE',
    ),
    productName: translate('OPS_PORTAL.LABEL.PRODUCT_NAME'),
    subProductName: translate('OPS_PORTAL.LABEL.SUB_PRODUCT_NAME'),
    storeId: translate('TITLE.STORE_ID'),
    arrearsManagedBy: translate('OPS_PORTAL.TITLE.ARREARS_MANAGED_BY'),
    collectionAssignmentManagedBy: translate(
      'OPS_PORTAL.TITLE.COLLECTIONS_ASSIGNMENT_MANAGED_BY',
    ),
    createdDate: translate('OPS_PORTAL.TITLE.CREATED_DATE'),
    maturityDate: translate('OPS_PORTAL.LABEL.MATURITY_DATE'),
    numberOfInstallments,
    originalNumberOfInstallments,
    monthlyPaymentAmount,
    currentPhase: translate('OPS_PORTAL.LABEL.CURRENT_PHASE'),
    identityStatus: translate('OPS_PORTAL.LABEL.IDENTITY_STATUS'),
    creditStatus: translate('OPS_PORTAL.LABEL.CREDIT_STATUS'),
    paymentMethodStatus: translate('OPS_PORTAL.LABEL.PAYMENT_METHOD_STATUS'),
    paymentMethod: translate('OPS_PORTAL.LABEL.PAYMENT_METHOD'),
    paymentLink: translate('OPS_PORTAL.LABEL.PAYMENT_LINK'),
    creditType: translate('OPS_PORTAL.LABEL.CREDIT_TYPE'),
    riskConfigName: translate('OPS_PORTAL.LABEL.RISK_CONFIG_NAME'),
    waterfallName: translate('OPS_PORTAL.LABEL.WATERFALL_CONFIG_NAME'),
  };

  if (collectionsBlockAutoReferralData && isArrearsToBeManagedByHeidi) {
    contractOverview.canBeReferredToExternalCollectors = translate(
      'OPS_PORTAL.LABEL.CAN_BE_REFERRED_TO_EXTERNAL_COLLECTORS',
    );
  }

  if (pauseCommunicationsData) {
    contractOverview.pauseCommunications = translate(
      'OPS_PORTAL.LABEL.AUTOMATED_COLLECTIONS_EMAILS_STOPPED',
    );
  }

  if (isCompass) {
    contractOverview.praticaId = translate('OPS_PORTAL.TITLE.PRATICA_ID');
  } else {
    contractOverview.paymentPlanReference = translate(
      'OPS_PORTAL.LABEL.PAYMENT_PLAN_REFERENCE',
    );
  }

  return contractOverview;
};
