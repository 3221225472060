import { PageRoutes } from '@utils/enums';
import { INavItems } from '../NavItems';

const permissionCanViewBankAccountStatementLines =
  'ops_portal.can_view_bank_account_statement_lines';
const svgProtectedShield = '/media/svg/icons/General/Shield-protected.svg';

export const BankingNavItems: INavItems = Object.freeze({
  BANK_ACCOUNT_RECONCILIATIONS: {
    iconPath: svgProtectedShield,
    link: PageRoutes.BankAccountReconciliations,
    scopes: [permissionCanViewBankAccountStatementLines],
    translationKey: 'OPS_PORTAL.TITLE.BANK_ACCOUNT_RECONCILIATIONS',
  },
  MEDIOBANCA_RECONCILIATIONS: {
    iconPath: '/media/svg/icons/General/Settings-1.svg',
    link: PageRoutes.Transactions,
    scopes: ['merchant_portal.can_view_mediobanca_reconciliation'],
    translationKey: 'TITLE.TRANSACTIONS',
  },
  PENDING_ALLOCATIONS: {
    iconPath: svgProtectedShield,
    link: PageRoutes.PendingAllocations,
    scopes: [permissionCanViewBankAccountStatementLines],
    translationKey:
      'OPS_PORTAL.LABEL.BANK_ACCOUNT_RECONCILIATIONS.PENDING_ALLOCATIONS',
  },
  SUSPENSE: {
    iconPath: svgProtectedShield,
    link: PageRoutes.Suspense,
    scopes: [permissionCanViewBankAccountStatementLines],
    translationKey: 'OPS_PORTAL.LABEL.BANK_ACCOUNT_RECONCILIATIONS.SUSPENSE',
  },
  UNBALANCED_ALLOCATIONS: {
    iconPath: svgProtectedShield,
    link: PageRoutes.UnbalancedAllocations,
    scopes: ['ops_portal.can_view_par_groups'],
    translationKey: 'OPS_PORTAL.TITLE.UNBALANCED_ALLOCATIONS',
  },
});
