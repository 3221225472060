import { CollectionAgencies } from '@appTypes';
import { Link } from '@components/Common.styles';
import {
  TableColumns,
  createTableColumnsV2,
} from '@components/TableV2/helpers/createTableColumnsV2';
import { useI18n } from '@hooks/useI18n';
import {
  IArrearsManagedBy,
  ICollection,
} from '@schemas/opsPortal/types/collections';
import { TabEventKeys } from '@utils/constants';

interface IUseGetCollectionsTableColumns {
  collectionAgencies: CollectionAgencies;
}

export const useGetCollectionsTableColumns = ({
  collectionAgencies,
}: IUseGetCollectionsTableColumns) => {
  const { getLocaleCurrencyValue, getLocaleDate, translate } = useI18n();

  const columns: TableColumns<ICollection> = [
    {
      field: 'contract_id',
      label: translate('OPS_PORTAL.LABEL.PAYMENT_PLAN_REFERENCE'),
      mapCustomJSX: ({ row }) => (
        <Link
          state={{ activeKey: TabEventKeys.CONTRACT_PERFORMANCE }}
          to={`/all_contracts/${row.external_uuid}`}
        >
          {row.payment_plan_reference}
        </Link>
      ),
    },
    {
      field: 'oldest_payment_due_date',
      label: translate('OPS_PORTAL.LABEL.CURRENT_ARREARS_DAY_ZERO'),
    },
    {
      field: 'customer_name',
      label: translate('TITLE.CUSTOMER_NAME'),
      mapCustomValue: ({ row }) => row.consumer_data?.name ?? '',
    },
    {
      field: 'merchant_name',
      label: translate('LABEL.MERCHANT_NAME'),
      mapCustomValue: ({ row }) =>
        row.merchant ? row.merchant.short_name : '',
    },
    {
      field: 'language',
      label: translate('LABEL.LANGUAGE'),
    },
    {
      field: 'arrears_amount',
      label: translate('LABEL.ARREARS_AMOUNT'),
      mapCustomValue: ({ row }) =>
        row.arrears_amount
          ? getLocaleCurrencyValue({
              currency: row.arrears_amount?.currency as string,
              value: row.arrears_amount?.amount as string,
            }) ?? ''
          : '',
      sortable: true,
    },
    {
      field: 'last_note_date',
      label: translate('OPS_PORTAL.LABEL.LAST_NOTE_DATE'),
      mapCustomValue: ({ row }) =>
        row.last_note_date ? getLocaleDate({ date: row.last_note_date }) : '',
    },
    {
      field: 'performance_status',
      label: translate('OPS_PORTAL.LABEL.PERFORMANCE_STATUS'),
      sortable: true,
    },
    {
      field: 'arrears_managed_by',
      label: translate('OPS_PORTAL.TITLE.ARREARS_MANAGED_BY'),
      mapCustomValue: ({ row }) =>
        row.arrears_managed_by
          ? collectionAgencies[row.arrears_managed_by as IArrearsManagedBy]
          : '',
    },
  ];

  return createTableColumnsV2({ columns });
};
