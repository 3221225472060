import { sortCurrencyString } from '@components/Table/utils/sortCurrencyString';
import { sortDateString } from '@components/Table/utils/sortDateString';
import {
  createTableColumnsV2,
  TableColumns,
} from '@components/TableV2/helpers/createTableColumnsV2';
import { Translate } from '@hooks/useI18n';
import { IPaymentsTableData } from '@pages/ContractDetails/utils/types';

export const getPaymentsTableColumns = (translate: Translate) => {
  const columns: TableColumns<IPaymentsTableData> = [
    {
      field: 'amount',
      label: translate('LABEL.AMOUNT'),
      sortable: true,
      sortComparator: sortCurrencyString,
    },
    {
      field: 'settlementDate',
      label: translate('OPS_PORTAL.LABEL.CUSTOMER_PAYMENT_DATE'),
      sortable: true,
      sortComparator: sortDateString,
    },
    {
      field: 'settlementCreatedDate',
      label: translate('OPS_PORTAL.LABEL.RECONCILIATION_DATE'),
      sortable: true,
      sortComparator: sortDateString,
    },
    {
      field: 'settlementReference',
      label: translate('OPS_PORTAL.LABEL.REFERENCE'),
      flexWidth: 1.8,
    },
    {
      field: 'settlementType',
      label: translate('OPS_PORTAL.LABEL.PAID_VIA'),
    },
    {
      field: 'prepayment',
      label: translate('TYPE.PREPAYMENT'),
    },
    {
      field: 'customerRefund',
      label: translate('TYPE.REFUND'),
    },
    {
      field: 'invalidated',
      label: translate('OPS_PORTAL.LABEL.INVALIDATED'),
    },
    {
      field: 'transactionInvalidationDate',
      label: translate('OPS_PORTAL.LABEL.INVALIDATION_DATE'),
      sortable: true,
      sortComparator: sortDateString,
    },
  ];

  return createTableColumnsV2({ columns });
};
