import { IPayment, IPaymentChartData } from '../types';

interface IMergedPaymentsData {
  date: string;
  due: number;
  paid: number;
}

export const getPaymentData = (
  duePayments: IPayment[],
  actualPayments: IPayment[],
): IPaymentChartData[] => {
  const mergedPaymentsData: IMergedPaymentsData[] = [];

  duePayments.forEach(({ amount, date }) => {
    mergedPaymentsData.push({ date, due: amount, paid: 0 });
  });

  actualPayments.forEach(({ amount, date }) => {
    const existingEntry = mergedPaymentsData.find(entry => entry.date === date);
    if (existingEntry) {
      existingEntry.paid += amount;
    } else {
      mergedPaymentsData.push({ date, due: 0, paid: amount });
    }
  });

  let totalDueToDate = 0;
  let totalPaidToDate = 0;

  return [...mergedPaymentsData]
    .sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime())
    .map(({ date, due, paid }) => {
      totalDueToDate += due;
      totalPaidToDate += paid;
      return {
        date,
        due,
        paid,
        totalDueToDate,
        totalPaidToDate,
      };
    });
};
