import { ValueOf } from '@appTypes';
import { ContractStatuses } from '@utils/constants';

interface ICanContractTriggerPayment {
  contractStatus: ValueOf<typeof ContractStatuses>;
  hasRemainder?: boolean;
}

export const canContractTriggerPayment = ({
  contractStatus,
  hasRemainder,
}: ICanContractTriggerPayment) => {
  const isContractActive = contractStatus === ContractStatuses.ACTIVE;
  const isContractTerminated =
    contractStatus === ContractStatuses.TERMINATED ||
    contractStatus === ContractStatuses.TERMINATED_AND_PAID;

  return hasRemainder && (isContractActive || isContractTerminated);
};
