import { AxiosError } from 'axios';
import { useToast } from '@heidi-pay/heidi-common-fe/hooks';
import { IError } from '@heidi-pay/heidi-common-fe/types';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useI18n } from '@hooks/useI18n';
import { usePortalError } from '@hooks/usePortalError';
import { IUuid } from '@schemas/merchantPortal/types/accountSettings';
import { QueryKeys } from '@utils/constants';
import { Endpoints } from '@utils/enums';
import { postDataToEndpoint } from '@utils/postDataToEndpoint';
import { IAddMerchantNoteForm } from '../types';

interface IUseCreateMerchantNoteQuery {
  closeModal?: () => void;
  uuid?: IUuid;
}

export const useCreateMerchantNoteQuery = ({
  closeModal,
  uuid,
}: IUseCreateMerchantNoteQuery) => {
  const queryClient = useQueryClient();
  const { translate } = useI18n();
  const { handleAxiosError } = usePortalError();
  const { message } = useToast(translate);

  const { isLoading: createMerchantNoteLoading, mutate: createMerchantNote } =
    useMutation<void, AxiosError<IError>, IAddMerchantNoteForm>(
      requestData =>
        postDataToEndpoint({
          endpoint: `${Endpoints.CreateMerchantNote}/${uuid}`,
          requestData: {
            note: requestData.note,
          },
        }),
      {
        onSuccess: () => {
          message.success(
            translate('OPS_PORTAL.SUCCESSFULLY_ADDED_MERCHANT_NOTE'),
          );
          queryClient.invalidateQueries([QueryKeys.MERCHANT_NOTES, uuid]);
          closeModal?.();
        },
        onError: error =>
          handleAxiosError(error, 'OPS_PORTAL.ERROR_ADDING_MERCHANT_NOTE'),
      },
    );

  return { createMerchantNoteLoading, createMerchantNote };
};
