import { format, addDays, differenceInCalendarWeeks } from 'date-fns';
import { IWeeklyDateGroup } from '../types';

export const getUniqueDatesByWeek = (dates: string[]): IWeeklyDateGroup[] => {
  if (dates.length === 0) {
    return [];
  }

  const sortedDates = [...dates].sort((a, b) => a.localeCompare(b));

  const startDate = new Date(sortedDates[0]);
  const endDate = new Date(sortedDates[sortedDates.length - 1]);

  const numWeeks = differenceInCalendarWeeks(endDate, startDate) + 1;
  const weekIntervals = Array.from({ length: numWeeks }, (_, i) => {
    const from = addDays(startDate, i * 7);
    const lastDateOfWeekInterval = addDays(from, 6);
    const to =
      lastDateOfWeekInterval > endDate ? endDate : lastDateOfWeekInterval;

    return {
      from: format(from, 'yyyy-MM-dd'),
      to: format(to, 'yyyy-MM-dd'),
    };
  });

  return weekIntervals
    .map(({ from, to }) => {
      const includedDates = sortedDates.filter(
        date => date >= from && date <= to,
      );

      return includedDates.length > 0 ? { from, to, includedDates } : null;
    })
    .filter(Boolean) as IWeeklyDateGroup[];
};
