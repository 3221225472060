import { useMemo, useState } from 'react';
import { AxiosError } from 'axios';
import { IStaticTableProps } from '@components/Table/StaticTable';
import { useI18n } from '@hooks/useI18n/useI18n';
import {
  CustomerAgreementDocumentTypes,
  IntrumDocumentTypes,
  OnfidoDocumentTypes,
  OpsPortalDocumentModes,
  TermsAndConditionsDocumentTypes,
  UploadedDocumentTypes,
} from './enums';
import { getDocumentsTableColumns } from './getDocumentTableColumns';
import { mapDocumentsTabTableData } from './mapDocumentsTabTableData';
import { useGetContractDocumentsQuery } from './queries/useGetContractDocumentsQuery';
import { useGetDownloadExecutionQueries } from './queries/useGetDownloadExecutionQueries';
import { useGetMerchantDocumentsQuery } from './queries/useGetMerchantDocumentsQuery';
import { IDocumentsTableColumn } from './types';
import { useDeleteDocumentModal } from './useDeleteDocumentModal';

interface IDocumentTableData {
  error: AxiosError | null;
  isLoading: boolean;
  tableProps: IStaticTableProps<IDocumentsTableColumn, unknown>;
}

export const useDocumentsTableData = (
  targetUuid: string,
  mode: OpsPortalDocumentModes,
): IDocumentTableData => {
  const { getLocaleDate, translate } = useI18n();

  const queryFunction =
    mode === OpsPortalDocumentModes.Contract
      ? useGetContractDocumentsQuery
      : useGetMerchantDocumentsQuery;
  const { data, error, isLoading } = queryFunction(targetUuid);

  const [documentDeleteDetails, setDocumentDeleteDetails] = useState({});
  const deleteDocumentModal = useDeleteDocumentModal({
    rowData: documentDeleteDetails as IDocumentsTableColumn,
    mode,
  });

  const {
    executeContractUserDocumentsDownload,
    executeCustomerAgreementDownload,
    executeIntrumDownload,
    executeMerchantUserDocumentsDownload,
    executeOnfidoDownload,
    executeTermsAndConditionsDownload,
  } = useGetDownloadExecutionQueries();

  const userDocumentDownload =
    mode === OpsPortalDocumentModes.Contract
      ? executeContractUserDocumentsDownload
      : executeMerchantUserDocumentsDownload;

  const contractDocumentsColumns = useMemo(
    () =>
      getDocumentsTableColumns({
        button: deleteDocumentModal?.button,
        translate,
        onDeleteClick: setDocumentDeleteDetails || (() => null),
        onDownloadClick: rowData => {
          const documentTypeExecutors = {
            ...Object.fromEntries(
              OnfidoDocumentTypes.map(type => [type, executeOnfidoDownload]),
            ),
            ...Object.fromEntries(
              IntrumDocumentTypes.map(type => [type, executeIntrumDownload]),
            ),
            ...Object.fromEntries(
              CustomerAgreementDocumentTypes.map(type => [
                type,
                executeCustomerAgreementDownload,
              ]),
            ),
            ...Object.fromEntries(
              TermsAndConditionsDocumentTypes.map(type => [
                type,
                executeTermsAndConditionsDownload,
              ]),
            ),
            ...Object.fromEntries(
              UploadedDocumentTypes.map(type => [type, userDocumentDownload]),
            ),
          };

          documentTypeExecutors[rowData.documentType](rowData);
        },
      }),
    [
      deleteDocumentModal?.button,
      executeCustomerAgreementDownload,
      executeIntrumDownload,
      executeOnfidoDownload,
      executeTermsAndConditionsDownload,
      userDocumentDownload,
      translate,
    ],
  );

  const documentsData = mapDocumentsTabTableData({
    data,
    getLocaleDate,
    translate,
  });

  return {
    tableProps: {
      columns: contractDocumentsColumns,
      data: documentsData,
    },
    error,
    isLoading,
  };
};
