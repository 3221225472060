import { AxiosError } from 'axios';
import { useQuery } from '@tanstack/react-query';
import { CollectionAgencies } from '@appTypes';
import { IOpsPortalCollectionAgencies } from '@schemas/opsPortal/types/collectionAgencies';
import { QueryKeys } from '@utils/constants';
import { Endpoints } from '@utils/enums';
import { fetchDataFromEndpoint } from '@utils/fetchDataFromEndpoint';
import { updateAgencyToHeyLightRebrand } from './updateAgencyToHeyLightRebrand';

interface IUseCollectionAgencies {
  collectionAgencies: CollectionAgencies;
  isCollectionAgenciesLoading: boolean;
}

export const useCollectionAgencies = (): IUseCollectionAgencies => {
  const { data, isLoading } = useQuery<
    IOpsPortalCollectionAgencies,
    AxiosError
  >([QueryKeys.COLLECTION_AGENCIES_LIST], () =>
    fetchDataFromEndpoint(Endpoints.FetchCollectionAgencies),
  );

  const updatedData = updateAgencyToHeyLightRebrand(data ?? []);
  const collectionAgencies = Object.fromEntries(
    updatedData.map(item => [item.key, item.value]),
  ) as CollectionAgencies;

  return {
    collectionAgencies,
    isCollectionAgenciesLoading: isLoading,
  };
};
