import { useParams } from 'react-router-dom';
import { ToCamelCase } from '@heidi-pay/heidi-common-fe/types';
import { addPlaceholderForEmptyValues } from '@heidi-pay/heidi-common-fe/utils';
import { objectKeysToCamelCase } from '@heidi-pay/heidi-common-fe/utils/objectKeysToCamelCase';
import { useUserDetails } from '@hooks/useUserDetails';
import { useGetRiskAndWaterfall } from '@pages/ContractDetails/hooks/queries';
import { IContractDetails } from '@schemas/opsPortal/types/contractDetails';
import { getFullName } from '@utils/getFullName';
import { IMerchantData } from './types';

interface IUseMerchantSection {
  contractDetails?: ToCamelCase<IContractDetails>;
}

export const useMerchantSection = ({
  contractDetails,
}: IUseMerchantSection) => {
  const { uuid: externalContractUuid } = useParams();
  const { isInternalOpsUser } = useUserDetails();

  const { riskAndWaterfallData } = useGetRiskAndWaterfall({
    externalContractUuid,
  });
  const riskAndWaterfallDetails = objectKeysToCamelCase(riskAndWaterfallData);

  const {
    merchantName,
    merchantOrderReference,
    merchantUser,
    opProductName,
    opSubproductName,
    orderDetails,
  } = contractDetails ?? {};

  const { email, firstName, lastName, phoneNumber } = merchantUser ?? {};
  const { riskConfigName, waterfallConfigName } = riskAndWaterfallDetails ?? {};

  const data = addPlaceholderForEmptyValues<IMerchantData>({
    merchantName,
    merchantUserId: email,
    merchantUserName: getFullName({ firstName, lastName }),
    merchantUserPhone: phoneNumber,
    merchantOrderRef: merchantOrderReference,
    productName: opProductName,
    subProductName: opSubproductName,
    storeId: orderDetails?.fullDetails?.storeId,
    riskConfigName,
    waterfallConfigName,
  });

  const merchantSection = [
    {
      label: 'LABEL.MERCHANT_NAME',
      value: data.merchantName,
    },
    {
      label: 'OPS_PORTAL.LABEL.MERCHANT_USER_ID',
      value: data.merchantUserId,
      dependency: isInternalOpsUser,
    },
    {
      label: 'OPS_PORTAL.LABEL.MERCHANT_USER_NAME',
      value: data.merchantUserName,
      dependency: isInternalOpsUser,
    },
    {
      label: 'OPS_PORTAL.LABEL.MERCHANT_USER_PHONE_NUMBER',
      value: data.merchantUserPhone,
      dependency: isInternalOpsUser,
    },
    {
      label: 'OPS_PORTAL.LABEL.MERCHANT_ORDER_REFERENCE',
      value: data.merchantOrderRef,
      dependency: isInternalOpsUser,
    },
    {
      label: 'OPS_PORTAL.LABEL.PRODUCT_NAME',
      value: data.productName,
      dependency: isInternalOpsUser,
    },
    {
      label: 'OPS_PORTAL.LABEL.SUB_PRODUCT_NAME',
      value: data.subProductName,
      dependency: isInternalOpsUser,
    },
    {
      label: 'TITLE.STORE_ID',
      value: data.storeId,
      dependency: isInternalOpsUser,
    },
    { label: 'OPS_PORTAL.LABEL.RISK_CONFIG_NAME', value: data.riskConfigName },
    {
      label: 'OPS_PORTAL.LABEL.WATERFALL_CONFIG_NAME',
      value: data.waterfallConfigName,
    },
  ].filter(field => field.dependency !== false);

  return { merchantSection };
};
