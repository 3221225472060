import { ColumnDef } from '@tanstack/react-table';
import { ReactTableColumns } from '@appTypes';
import { Translate } from '@hooks/useI18n';
import { ICustomTableColumns } from '@schemas/merchantPortal/types/accountSettings';

export const getCustomTableColumns = <T>(
  portalConfig: ICustomTableColumns,
  defaultColumns: Partial<Record<keyof T, ColumnDef<T, string>>>,
  translate: Translate,
) =>
  portalConfig.map(configItem => {
    const col = defaultColumns[configItem.dataKey as keyof T];

    return configItem.titleTranslationOverrideKey
      ? {
          ...col,
          header: translate(configItem.titleTranslationOverrideKey),
        }
      : col;
  }) as ReactTableColumns<T>;
