import {
  TableColumns,
  createTableColumnsV2,
} from '@components/TableV2/helpers/createTableColumnsV2';
import {
  IAgencyAssignmentCountData,
  IUseGetAgencyAssignmentsColumns,
} from './types';
import { generateColumnsByWeek } from './utils/generateColumnsByWeek';
import { getAgencyColumn } from './utils/getAgencyColumn';
import { getBucketColumn } from './utils/getBucketColumn';

export const useGetAgencyAssignmentsByAgencyBucketWeeks = ({
  dates,
  translate,
}: IUseGetAgencyAssignmentsColumns) => {
  const datesColumns = generateColumnsByWeek<IAgencyAssignmentCountData>(dates);
  const columns: TableColumns<IAgencyAssignmentCountData> = [
    getAgencyColumn(translate),
    getBucketColumn(translate),
    ...datesColumns,
  ];

  return createTableColumnsV2({ columns });
};
