import { format as formatDate } from 'date-fns';
import { styled } from '@mui/material/styles';
import {
  createTableColumnsV2,
  TableColumns,
} from '@components/TableV2/helpers/createTableColumnsV2';
import { useI18n } from '@hooks/useI18n';
import { IEmail } from '@schemas/consumerPortal/types/accountSettings';
import { IDateTime } from '@schemas/merchantPortal/types/accountSettings';
import { IString } from '@schemas/opsPortal/types/crabb';
import { missingValuePlaceholder } from '@utils/constants';

const TableCell = styled('div')({
  padding: '12px',
  whiteSpace: 'pre-wrap',
});

export interface INotesTableData {
  created: IDateTime;
  external_created_by: IEmail;
  note: IString;
}

export const useGetNotesTableColumns = () => {
  const { translate } = useI18n();

  const columns: TableColumns<INotesTableData> = [
    {
      field: 'created',
      label: translate('OPS_PORTAL.LABEL.CREATED_DATE'),
      mapCustomJSX: ({ row }) => (
        <TableCell>
          {row.created
            ? formatDate(new Date(row.created), 'yyyy-MM-dd')
            : missingValuePlaceholder}
        </TableCell>
      ),
    },
    {
      field: 'note',
      label: translate('OPS_PORTAL.LABEL.NOTE'),
      mapCustomJSX: ({ row }) => <TableCell>{row.note}</TableCell>,
      flexWidth: 2,
    },
    {
      field: 'external_created_by',
      label: translate('OPS_PORTAL.LABEL.CREATED_BY'),
      mapCustomJSX: ({ row }) => (
        <TableCell>
          {row.external_created_by || missingValuePlaceholder}
        </TableCell>
      ),
    },
  ];

  return createTableColumnsV2({ columns });
};
