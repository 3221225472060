import { IOpsPortalAggregateAgencyAssignments } from '@schemas/opsPortal/types/aggregateAgencyAssignments';
import { AgencyKey, AgencyKeys, BucketKey, BucketKeys } from './types';

export const defaultAggregateAgencyAssignments: IOpsPortalAggregateAgencyAssignments =
  {
    CD1: {},
    CD1C: {},
    CD1F: {},
    CD1I: {},
    CD1Q: {},
    CD2: {},
    CD3: {},
    CD4: {},
    CD5: {},
    CD6: {},
    DEA: {},
    FRA: {},
    TER1: {},
  };

export const defaultBucketCounts: Record<BucketKey, number> = {
  CD1: 0,
  CD1C: 0,
  CD1F: 0,
  CD1I: 0,
  CD1Q: 0,
  CD2: 0,
  CD3: 0,
  CD4: 0,
  CD5: 0,
  CD6: 0,
  DEA: 0,
  FRA: 0,
  TER1: 0,
};

export const defaultAgenciesCount: Record<AgencyKey, number> = {
  Covisian: 0,
  CreditReform: 0,
  Cribis: 0,
  Heidipay: 0,
  Inkasso: 0,
  'Sonia Laurenco': 0,
  'Studio Lanza Gia': 0,
};

export const allBucketNames: BucketKeys = Object.keys(
  defaultAggregateAgencyAssignments,
) as BucketKeys;
export const allAgencyNames: AgencyKeys = Object.keys(
  defaultAgenciesCount,
) as AgencyKeys;
