import { createColumnHelper } from '@tanstack/react-table';
import { Translate } from '@hooks/useI18n';
import { ExpandableCrabbData } from './ExpandableCrabbData';
import { ICrabbTableData } from './types';

interface IGetCrabbTableColumns {
  translate: Translate;
}

export const getCrabbTableColumns = ({ translate }: IGetCrabbTableColumns) => {
  const columnHelper = createColumnHelper<ICrabbTableData>();

  return [
    columnHelper.accessor('rawResponseData', {
      header: translate('OPS_PORTAL.LABEL.CRABB_DATA'),
      enableSorting: false,
      cell: ({ row }) => (
        <ExpandableCrabbData row={row} translate={translate} />
      ),
    }),
  ];
};
