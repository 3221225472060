import { format } from 'date-fns';

export const getDatesToFromToday = (daysGap: number) => {
  const today = new Date();
  const pastDate = new Date();
  pastDate.setDate(today.getDate() - daysGap);
  return {
    from: format(pastDate, 'yyyy-MM-dd'),
    to: format(today, 'yyyy-MM-dd'),
  };
};
