import { useMemo, useState } from 'react';
import { AxiosError } from 'axios';
import { useQueries } from '@tanstack/react-query';
import { IActionButton, PartialEnumValueAsKey } from '@appTypes';
import { Card } from '@components/Card';
import { QueryStateRouter } from '@components/QueryStateRouter';
import { ClientsideTable } from '@components/Table/ClientsideTable';
import { useI18n } from '@hooks/useI18n/useI18n';
import { useViewCommunicationModal } from '@hooks/useModal/hooks/useViewCommunicationModal';
import { QueryKeys, TableNames } from '@utils/constants';
import { Endpoints } from '@utils/enums';
import { fetchDataFromEndpoint } from '@utils/fetchDataFromEndpoint';
import { EventColumnAccessor } from './enums';
import { getEventsTimelineColumns } from './getEventsTimelineColumns';
import { getGenericEvents as getEvents } from './getGenericEvents';
import { ICommunication, IGenericEvent } from './types.d';

interface IEventsTimelineCard {
  contractUuid: string | undefined;
}

const hiddenColumns: PartialEnumValueAsKey<EventColumnAccessor, boolean> = {
  communicationDirection: false,
  communicationStatus: false,
  communicationType: false,
  paymentCaptureStatus: false,
  paymentProcessingStatus: false,
};

export const EventsTimeline = ({ contractUuid }: IEventsTimelineCard) => {
  const { getLocaleDate, translate } = useI18n();

  const enabled = Boolean(contractUuid);

  const [
    {
      data: contractCommunicationEvents,
      error: communicationEventsError,
      isLoading: communicationEventsLoading,
    },
    {
      data: paymentEvents,
      error: paymentEventsError,
      isLoading: paymentEventsLoading,
    },
    {
      data: collectionsEvents,
      error: collectionsEventsError,
      isLoading: collectionsEventsLoading,
    },
  ] = useQueries<
    [
      [IGenericEvent[], AxiosError],
      [IGenericEvent[], AxiosError],
      [IGenericEvent[], AxiosError],
    ]
  >({
    queries: [
      {
        queryKey: [QueryKeys.COMMUNICATIONS_EVENTS, contractUuid],
        queryFn: () =>
          fetchDataFromEndpoint(
            `${Endpoints.FetchCommunicationsEvents}/${contractUuid}`,
          ),
        enabled,
      },
      {
        queryKey: [QueryKeys.PAYMENTS_EVENTS, contractUuid],
        queryFn: () =>
          fetchDataFromEndpoint(
            `${Endpoints.FetchPaymentsEvents}/${contractUuid}`,
          ),
        enabled,
      },
      {
        queryKey: [QueryKeys.COLLECTIONS_TIMELINE_EVENTS, contractUuid],
        queryFn: () =>
          fetchDataFromEndpoint(
            `${Endpoints.FetchTimelineEvents}/${contractUuid}`,
          ),
        enabled,
      },
    ],
  });

  const [communicationProps, setCommunicationProps] = useState<ICommunication>(
    {},
  );

  const viewCommunicationModal = useViewCommunicationModal({
    ...communicationProps,
  });

  const columns = useMemo(
    () =>
      getEventsTimelineColumns({
        button: viewCommunicationModal?.button as IActionButton,
        setCommunicationProps,
        translate,
      }),
    [translate, viewCommunicationModal?.button],
  );

  const allData: IGenericEvent[] = [
    ...(contractCommunicationEvents ?? []),
    ...(paymentEvents ?? []),
    ...(collectionsEvents ?? []),
  ].sort(
    (a, b) => new Date(a.timestamp).valueOf() - new Date(b.timestamp).valueOf(),
  );

  const mappedEvents = getEvents(allData, getLocaleDate);

  return (
    <Card title={translate('OPS_PORTAL.TITLE.EVENTS_TIMELINE')}>
      <QueryStateRouter
        error={
          communicationEventsError ??
          paymentEventsError ??
          collectionsEventsError
        }
        isLoading={
          communicationEventsLoading ||
          paymentEventsLoading ||
          collectionsEventsLoading
        }
      >
        <ClientsideTable
          columns={columns}
          columnVisibility={hiddenColumns}
          data={mappedEvents}
          enableRowSelection={true}
          tableName={TableNames.EVENTS_TIMELINE}
        />
      </QueryStateRouter>
    </Card>
  );
};
