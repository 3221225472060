/* eslint-disable max-nested-callbacks */
import { IOpsPortalAggregateAgencyAssignments } from '@schemas/opsPortal/types/aggregateAgencyAssignments';
import { allAgencyNames, allBucketNames } from '../constants';
import { AgencyKey } from '../types';

export interface IAgencyAssignment {
  agency: AgencyKey;
  assignmentCounts: Record<string, number>;
  bucket: string;
}

export const getTotalBucketCountsPerDatePerAgency = (
  data: IOpsPortalAggregateAgencyAssignments,
): IAgencyAssignment[] => {
  const results: IAgencyAssignment[] = [];

  const allDates = new Set<string>();
  Object.values(data).forEach(dates => {
    Object.keys(dates).forEach(date => allDates.add(date));
  });

  allAgencyNames.forEach(agency => {
    allBucketNames.forEach(bucket => {
      const assignmentCounts: Record<string, number> = {};
      allDates.forEach(date => {
        assignmentCounts[date] = 0;
      });

      if (bucket in data) {
        const bucketData =
          data[bucket as keyof IOpsPortalAggregateAgencyAssignments];

        Object.entries(bucketData).forEach(([date, assignments]) => {
          assignmentCounts[date] =
            (assignments as unknown as Record<string, number>)[agency] || 0;
        });
      }

      results.push({
        agency,
        bucket,
        assignmentCounts,
      });
    });
  });

  return results;
};
