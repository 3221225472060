/* eslint-disable max-len, max-lines */
import { useCallback, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { DataGrid } from '@components/Common.styles';
import { Filters } from '@components/Filters/Filters';
import { TotalItemsContainer } from '@components/Pagination/Pagination.styled';
import { useI18n } from '@hooks/useI18n';
import { NoRowsOverlayCustom } from './components/NoRowsOverlayCustom';
import { TableType } from './enums';
import { defaultPageSizeOptions } from './helpers/constants';
import { formatQueryParams } from './helpers/utils';
import { useCursorPaginationChangeHandler } from './hooks/useCursorPaginationChangeHandler';
import { useFilterChangeHandler } from './hooks/useFilterChangeHandler';
import { useSyncSortSearchParamsEffect } from './hooks/useSyncSortSearchParamsEffect';
import { useSyncURLParamsCursorPagination } from './hooks/useSyncURLParamsCursorPagination';
import { TableWrapper } from './TableWrapper';
import { IHandlePageSizeChange, ITableCursorPaginationV2 } from './types';
import { processTableData } from './utils';

export const TableCursorPaginationV2 = <TFilterValues,>({
  columns = [],
  columnVisibility = {},
  count,
  data = [],
  error,
  filterSettings,
  isInTab = false,
  isLoading,
  minWidth,
  name,
  paginationSettings,
  rowSelectionSettings,
  sortingSettings,
  title,
  wrapTableInCard = true,
}: ITableCursorPaginationV2<TFilterValues>) => {
  const { translate } = useI18n();
  const [searchParams, setSearchParams] = useSearchParams(
    new URLSearchParams(window.location.search),
  );

  const processedData = processTableData(data);

  const {
    cursors,
    handlePagination,
    pageSize,
    pageSizeOptions = defaultPageSizeOptions,
    paginationParams,
  } = paginationSettings;

  const {
    filterHandler,
    filters,
    filterValues,
    initialDefaultValues,
    resetFiltersHandler: resetFilters,
    showClearFiltersButton = true,
  } = filterSettings ?? {};
  const formattedInitialDefaults = useMemo(
    () => formatQueryParams(initialDefaultValues as object),
    [initialDefaultValues],
  );

  // This is to keep track of the url being updated manually
  const [syncedSearchParams, setSyncedSearchParams] = useState(
    formattedInitialDefaults,
  );

  const { allowRowSelection = false, handleRowSelection } =
    rowSelectionSettings ?? {};

  const { handleSortModelChange, sortModel, sortSearchParam } =
    sortingSettings ?? {};

  useSyncURLParamsCursorPagination({
    handlePagination,
    handleSortModelChange,
    filterHandler,
    searchParams,
    setSyncedSearchParams,
    syncedSearchParams,
  });

  useSyncSortSearchParamsEffect({
    searchParams,
    setSearchParams,
    setSyncedSearchParams,
    sortSearchParam,
  });

  const handleCursorPaginationChange =
    useCursorPaginationChangeHandler<TFilterValues>({
      cursors,
      filterValues,
      handlePagination,
      pageSize,
      paginationParams,
      setSearchParams,
      setSyncedSearchParams,
      sortSearchParam,
    });

  const handlePaginationReset = () => {
    handleCursorPaginationChange({ pageSize, reset: true });
  };

  const handleFilterChange = useFilterChangeHandler<TFilterValues>({
    handlePaginationReset,
    filterHandler,
    filterValues,
    isCursorPagination: true,
    paginationParams,
    setSearchParams,
    setSyncedSearchParams,
    sortSearchParam,
  });

  const handleFilterReset = useCallback(() => {
    handleCursorPaginationChange({ pageSize, reset: true });
    resetFilters?.();
    handleSortModelChange?.([]);
    setSyncedSearchParams(formattedInitialDefaults ?? '');
    setSearchParams(formattedInitialDefaults ?? '');
  }, [
    formattedInitialDefaults,
    handleCursorPaginationChange,
    handleSortModelChange,
    pageSize,
    resetFilters,
    setSearchParams,
  ]);

  const handlePageSizeChange = useCallback(
    ({ pageSize: newPageSize }: IHandlePageSizeChange) => {
      handleCursorPaginationChange({ pageSize: newPageSize });
    },
    [handleCursorPaginationChange],
  );

  return (
    <TableWrapper
      error={error}
      isLoading={isLoading}
      title={title}
      wrapTableInCard={wrapTableInCard}
    >
      {filters ? (
        <Filters
          filterHandler={handleFilterChange}
          filters={filters}
          isInTab={isInTab}
          resetFilters={handleFilterReset}
          showClearFiltersButton={showClearFiltersButton}
        />
      ) : null}
      <DataGrid
        aria-label={name}
        autoHeight={true}
        checkboxSelection={allowRowSelection}
        clipboardCopyCellDelimiter=","
        columnHeaderHeight={56}
        columns={columns}
        disableColumnMenu={true}
        disableRowSelectionOnClick={!allowRowSelection}
        initialState={{
          columns: {
            columnVisibilityModel: columnVisibility,
          },
        }}
        isCursorPagination={true}
        minWidth={minWidth}
        onPaginationModelChange={handlePageSizeChange}
        onRowSelectionModelChange={handleRowSelection}
        onSortModelChange={handleSortModelChange}
        pageSizeOptions={pageSizeOptions}
        pagination={true}
        paginationMode={TableType.SERVER}
        paginationModel={{
          page: 0,
          pageSize,
          cursor: paginationParams.cursor,
        }}
        rowCount={count}
        rowHeight={40}
        rows={processedData}
        slotProps={{
          pagination: {
            backIconButtonProps: {
              disabled: !cursors?.prevCursor,
              onClick: () =>
                handleCursorPaginationChange({
                  pageSize,
                  isNextClicked: false,
                }),
            },
            nextIconButtonProps: {
              disabled: !cursors?.nextCursor,
              onClick: () =>
                handleCursorPaginationChange({
                  pageSize,
                  isNextClicked: true,
                }),
            },
          },
        }}
        slots={{
          noRowsOverlay: NoRowsOverlayCustom,
        }}
        sortModel={sortModel}
      />
      <TotalItemsContainer>{`${translate(
        'LABEL.TOTAL_ITEMS',
      )}: ${count}`}</TotalItemsContainer>
    </TableWrapper>
  );
};
