import { useState } from 'react';
import { Line } from 'react-chartjs-2';
import {
  Chart,
  CategoryScale,
  LinearScale,
  TimeScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import 'chartjs-adapter-date-fns';
import { Box, styled } from '@mui/material';
import { H3 } from '@components/Common.styles';
import { ClientTableV2 } from '@components/TableV2/ClientTableV2';
import { Toggle } from '@components/Toggle';
import { useI18n } from '@hooks/useI18n';
import { IOpsPortalMonetaContract } from '@schemas/opsPortal/types/monetaContract';
import { TableNames } from '@utils/constants';
import { getPaymentChartData } from './getPaymentChartData';
import { getPaymentChartOptions } from './getPaymentChartOptions';
import { getPaymentData } from './getPaymentData';
import { getPaymentsChartTableColumns } from './getPaymentsChartTableColumns';
import { mapPaymentsChartData } from './mapPaymentsChartData';

Chart.register(
  CategoryScale,
  LinearScale,
  TimeScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
);

const ChartContainer = styled(Box)({
  height: 'auto',
  maxHeight: '500px',
  margin: '50px 0',
  width: '100%',
  justifyContent: 'center',
  display: 'flex',
});

const SubTitle = styled(Box)({
  fontSize: '0.875em',
  color: '#666',
});

const StyledLineChart = styled(Line)({ maxWidth: '1200px', height: '500px' });

interface IPaymentChart {
  monetaContractData?: IOpsPortalMonetaContract;
}

export const PaymentsChart = ({ monetaContractData }: IPaymentChart) => {
  const [paymentDatesUsed, setPaymentDatesUsed] = useState(false);
  const { getLocaleCurrencyValue, getLocaleDate, translate } = useI18n();

  if (!monetaContractData) {
    return null;
  }

  const { balances, currency, settlements } = monetaContractData;

  const maxScheduleVersion = Math.max(
    ...monetaContractData.balances.map(balance => balance.schedule_version),
  );

  const latestBalances = balances.filter(
    balance => balance.schedule_version === maxScheduleVersion,
  );

  const paymentData = getPaymentData(
    latestBalances.map(balance => ({
      amount: parseFloat(balance.amount_due),
      date: balance.payment_date,
    })),
    settlements.map(settlement => ({
      amount: parseFloat(settlement.amount.amount),
      date: paymentDatesUsed
        ? settlement.settlement_date
        : settlement.settlement_created_date,
    })),
  );

  const data = getPaymentChartData({ translate, paymentData });
  const options = getPaymentChartOptions({ translate, currency });
  const contractPaymentsColumns = getPaymentsChartTableColumns(translate);
  const paymentChartsTableData = mapPaymentsChartData({
    currency,
    data: paymentData,
    getLocaleCurrencyValue,
    translate,
    getLocaleDate,
  });

  return (
    <>
      <H3>{translate('OPS_PORTAL.TITLE.PAYMENTS_CUMULATIVE')}</H3>
      <SubTitle>
        {translate('OPS_PORTAL.TITLE.CHART_GROUPED_BY_RECONCILE_DATE')}
      </SubTitle>
      <Toggle
        handleSwitch={setPaymentDatesUsed}
        isOn={paymentDatesUsed}
        label={translate(
          'OPS_PORTAL.LABEL.GROUP_CHART_BY_CUSTOMER_PAYMENT_DATE',
        )}
      />
      <ChartContainer>
        <StyledLineChart data={data} options={options} />
      </ChartContainer>
      <ClientTableV2
        columns={contractPaymentsColumns}
        data={paymentChartsTableData}
        minWidth="1000px"
        name={TableNames.PAYMENTS_CHART}
        wrapTableInCard={false}
      />
    </>
  );
};
