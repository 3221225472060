// this charting colour scheme comes from this website
// https://www.viget.com/articles/add-colors-to-your-palette-with-color-mixing/
const colourPalettes = {
  small: ['#1495ba', '#0d3d55', '#f16c20'],
  medium: ['#1495ba', '#0d3d55', '#c02e1d', '#f16c20', '#ebc844', '#a2b86c'],
  large: [
    '#1495ba',
    '#117899',
    '#0f5b78',
    '#0d3d55',
    '#c02e1d',
    '#d94e20',
    '#f16c20',
    '#ef8b2c',
    '#ecaa38',
    '#ebc844',
    '#a2b86c',
    '#5ca793',
  ],
};

export const getChartColourPalette = (numDatasets: number) => {
  if (numDatasets <= 3) {
    return colourPalettes.small;
  }
  if (numDatasets <= 6) {
    return colourPalettes.medium;
  }
  return colourPalettes.large;
};
