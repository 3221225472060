/* eslint-disable max-len */
import { CopyText } from '@components/CopyText';
import { useI18n } from '@hooks/useI18n/useI18n';
import { IOpsPortalApplicationDetails } from '@schemas/opsPortal/types/applicationDetails';
import { missingValuePlaceholder } from '@utils/constants';
import { useGetConsumerCommunicationsByTriggerQuery } from '../hooks/queries/useGetConsumerCommunicationsByTriggerQuery';
import { getCheckoutUrlFromRecentCommunicationsEvent } from '../utils';

interface ICheckoutLink {
  data?: IOpsPortalApplicationDetails;
}

export const CheckoutLink = ({ data }: ICheckoutLink) => {
  const { translate } = useI18n();

  const { consumerCommunicationsByTriggerData } =
    useGetConsumerCommunicationsByTriggerQuery({
      consumerUuid: data?.consumer_uuid,
      communicationTrigger: 'CHECKOUT_LINK',
      contractUuid: data?.contract_uuid,
    });

  const checkoutLink = getCheckoutUrlFromRecentCommunicationsEvent(
    consumerCommunicationsByTriggerData,
  );

  return checkoutLink ? (
    <CopyText
      copyLabel={translate('OPS_PORTAL.LABEL.CHECKOUT_LINK_COPY')}
      copyText={checkoutLink}
      errorMessage={translate('OPS_PORTAL.LABEL.CHECKOUT_LINK_FAILED_TO_COPY')}
      successMessage={translate(
        'OPS_PORTAL.LABEL.CHECKOUT_LINK_SUCCESSFULLY_COPIED',
      )}
    />
  ) : (
    <>{missingValuePlaceholder}</>
  );
};
