import { ReactNode } from 'react';
import { AxiosError } from 'axios';
import { Nullable } from '@heidi-pay/heidi-common-fe/types';
import { ActionButtons } from '@appTypes';
import { Card } from '@components/Card';
import { Loading } from '@components/Loading';
import { QueryStateRouter } from '@components/QueryStateRouter';

interface ITableWrapper {
  buttons?: ActionButtons;
  children: ReactNode;
  error?: Nullable<AxiosError>;
  isLoading?: boolean;
  title?: string;
  wrapTableInCard: boolean;
}

export const TableWrapper = ({
  buttons,
  children,
  error,
  isLoading,
  title,
  wrapTableInCard,
}: ITableWrapper) =>
  wrapTableInCard ? (
    <QueryStateRouter error={error}>
      <Card buttons={buttons} title={title}>
        {isLoading ? <Loading /> : children}
      </Card>
    </QueryStateRouter>
  ) : (
    <>{isLoading ? <Loading /> : children}</>
  );
