import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { Button as MuiButton, styled } from '@mui/material';
import { useFeatureFlags } from '@hooks/useFeatureFlags';
import { useHasPermission } from '@hooks/useHasPermission';
import { useI18n } from '@hooks/useI18n';
import { FeatureFlag } from '@redux/featureFlag/enums';
import { setFeatureFlag } from '@redux/featureFlag/featureFlagSlice';

const Button = styled(MuiButton)`
  color: #6c757d;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
  text-transform: initial;
  padding: 8px 12px;
  font-size: 12px;

  &:hover {
    color: #6c757d;
    background-color: #e2e6ea;
    border-color: #dae0e5;
  }
`;

export const ToggleExperiementalUIButton = () => {
  const dispatch = useDispatch();
  const { translate } = useI18n();
  const canViewNewUI = useHasPermission(['ops_portal.can_view_beta_features']);
  const { isExperimentalUIEnabled } = useFeatureFlags();

  const toggleExperimentalUI = useCallback(() => {
    dispatch(
      setFeatureFlag({
        flagName: FeatureFlag.ExperimentalUI,
        enabled: !isExperimentalUIEnabled,
      }),
    );
  }, [isExperimentalUIEnabled, dispatch]);

  if (!canViewNewUI) {
    return null;
  }

  const buttonLabel = isExperimentalUIEnabled ? 'DISABLE' : 'ENABLE';

  return (
    <Button onClick={toggleExperimentalUI} variant="outlined">
      {translate(`OPS_PORTAL.BUTTON.${buttonLabel}_NEW_UI`)}
    </Button>
  );
};
