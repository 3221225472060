import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ReduxPersistKeys } from '@utils/constants';
import { FeatureFlag } from './enums';
import { IFeatureFlags } from './types';

const initialState: IFeatureFlags = {
  flags: {
    [FeatureFlag.ExperimentalUI]: false,
  },
};

export const featureFlagsSlice = createSlice({
  name: 'featureFlags',
  initialState,
  reducers: {
    setFeatureFlag: (
      state,
      action: PayloadAction<{
        enabled: boolean;
        flagName: FeatureFlag;
      }>,
    ) => {
      const { enabled, flagName } = action.payload;
      return {
        ...state,
        flags: {
          ...state.flags,
          [flagName]: enabled,
        },
      };
    },
  },
});

export const { setFeatureFlag } = featureFlagsSlice.actions;

const persistConfig = {
  key: ReduxPersistKeys.FEATURE_FLAGS,
  storage,
};

export const persistedFeatureFlagsReducer = persistReducer(
  persistConfig,
  featureFlagsSlice.reducer,
);
