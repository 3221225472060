import { CrabbStatus } from '../enums';

const translationsKeyMap = {
  [CrabbStatus.CREATED]: 'OPS_PORTAL.LABEL.CREATED',
  [CrabbStatus.FAILED]: 'STATUS.FAILED',
  [CrabbStatus.UNKNOWN]: 'STATUS.UNKNOWN',
  [CrabbStatus.UPDATED]: 'OPS_PORTAL.LABEL.UPDATED',
};

export const getTranslationKey = (status: string) =>
  translationsKeyMap[status as CrabbStatus] ?? 'STATUS.UNKNOWN';
