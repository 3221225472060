import { addPlaceholderForEmptyValues } from '@heidi-pay/heidi-common-fe/utils/addPlaceholderForEmptyValues';
import {
  GetLocaleCurrencyValue,
  GetLocaleDate,
  Translate,
} from '@hooks/useI18n';
import { IPaymentChartData, IPaymentChartDataMapped } from '../types';

interface IMapPaymentDataProps {
  currency: string;
  data?: IPaymentChartData[];
  getLocaleCurrencyValue: GetLocaleCurrencyValue;
  getLocaleDate: GetLocaleDate;
  translate: Translate;
}

export const mapPaymentsChartData = ({
  currency,
  data,
  getLocaleCurrencyValue,
  getLocaleDate,
}: IMapPaymentDataProps): IPaymentChartDataMapped[] =>
  data?.map(dataRow => {
    const { date, due, paid, totalDueToDate, totalPaidToDate } = dataRow;

    return addPlaceholderForEmptyValues<IPaymentChartDataMapped>({
      date: getLocaleDate({ date }),
      due: due
        ? getLocaleCurrencyValue({
            currency,
            value: due.toFixed(2),
          })
        : null,
      paid: paid
        ? getLocaleCurrencyValue({
            currency,
            value: paid.toFixed(2),
          })
        : null,
      totalDueToDate: getLocaleCurrencyValue({
        currency,
        value: totalDueToDate.toFixed(2),
      }),
      totalPaidToDate: getLocaleCurrencyValue({
        currency,
        value: totalPaidToDate.toFixed(2),
      }),
    });
  }) ?? [];
