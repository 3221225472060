import {
  TableColumns,
  createTableColumnsV2,
} from '@components/TableV2/helpers/createTableColumnsV2';
import {
  IBucketAssignmentCountData,
  IUseGetAgencyAssignmentsColumns,
} from './types';
import { generateColumnsByWeek } from './utils/generateColumnsByWeek';
import { getBucketColumn } from './utils/getBucketColumn';

export const useGetAgencyAssignmentsColumnsWeeks = ({
  dates,
  translate,
}: IUseGetAgencyAssignmentsColumns) => {
  const datesColumns = generateColumnsByWeek<IBucketAssignmentCountData>(dates);

  const columns: TableColumns<IBucketAssignmentCountData> = [
    getBucketColumn(translate),
    ...datesColumns,
  ];

  return createTableColumnsV2({ columns });
};
