import { sortDateString } from '@components/Table/utils/sortDateString';
import {
  createTableColumnsV2,
  TableColumns,
} from '@components/TableV2/helpers/createTableColumnsV2';
import { Translate } from '@hooks/useI18n';
import { IPaymentChartData } from '../types';

export const getPaymentsChartTableColumns = (translate: Translate) => {
  const columns: TableColumns<IPaymentChartData> = [
    {
      field: 'date',
      label: translate('LABEL.DATE'),
      sortable: true,
      sortComparator: sortDateString,
    },
    {
      field: 'due',
      label: translate('OPS_PORTAL.LABEL.DUE'),
    },
    {
      field: 'paid',
      label: translate('OPS_PORTAL.LABEL.PAID'),
    },
    {
      field: 'totalDueToDate',
      label: translate('OPS_PORTAL.LABEL.TOTAL_DUE_TO_DATE'),
    },
    {
      field: 'totalPaidToDate',
      label: translate('OPS_PORTAL.LABEL.TOTAL_PAID_TO_DATE'),
    },
  ];

  return createTableColumnsV2({ columns });
};
