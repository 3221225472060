import { TableColumns } from '@components/TableV2/helpers/createTableColumnsV2';
import { IAssignmentsCountData, IWeeklyDateGroup } from '../types';
import { getUniqueDatesByWeek } from './getUniqueDatesByWeek';

export const generateColumnsByWeek = <T extends IAssignmentsCountData>(
  dates: string[],
): TableColumns<T> => {
  const dataByWeek = getUniqueDatesByWeek(dates);

  return dataByWeek.map((week: IWeeklyDateGroup) => {
    const { from, includedDates, to } = week;
    const label = `${from} - ${to}`;
    return {
      field: label,
      label,
      mapCustomValue: ({ row }: { row: T }) => {
        let total = 0;
        includedDates.forEach(includedDate => {
          total += row.assignmentCounts?.[includedDate] ?? 0;
        });
        return total.toString();
      },
    };
  }) as TableColumns<T>;
};
