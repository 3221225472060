import { IFullOrderDetailsProducts } from '@schemas/opsPortal/types/contracts';
import { sort } from '@utils/sort';

export const getOrderProductDetails = (
  products?: IFullOrderDetailsProducts,
) => {
  const highestValueProduct = products?.sort((a, b) =>
    sort(Number(b.price), Number(a.price)),
  )?.[0];

  const productSku = highestValueProduct?.sku;
  const product = highestValueProduct?.name;

  // product code and product ID are used for CSV download,
  // ',' added at the end to ensure the '0' isn't removed from the front.
  const productCode = productSku ? `${productSku},` : null;
  const productId = highestValueProduct?.external_id
    ? `${highestValueProduct.external_id},`
    : null;

  return {
    product,
    productId,
    productCode,
    productSku,
  };
};
