import { useParams } from 'react-router-dom';
import { ClientTableV2 } from '@components/TableV2/ClientTableV2';
import { useI18n } from '@hooks/useI18n';
import { useAddMerchantNoteModal } from '@pages/MerchantDetails/modals/addMerchantNoteModal/useAddMerchantNoteModal';
import { useGetMerchantNotesQuery } from '@pages/MerchantDetails/queries';
import { useGetNotesTableColumns } from './getMerchantNotesTableColumns';

export const NotesTable = () => {
  const { uuid } = useParams();
  const column = useGetNotesTableColumns();

  const { translate } = useI18n();
  const { isMerchantNotesLoading, merchantNotesData, merchantNotesError } =
    useGetMerchantNotesQuery({ uuid });

  const tableData = merchantNotesData?.notes ?? [];

  const addMerchantModal = useAddMerchantNoteModal({
    uuid,
  });

  return (
    <ClientTableV2
      buttons={[addMerchantModal?.button]}
      columns={column}
      data={tableData}
      error={merchantNotesError}
      isLoading={isMerchantNotesLoading}
      name={translate('OPS_PORTAL.TITLE.MERCHANT_NOTES')}
      title={translate('OPS_PORTAL.TITLE.MERCHANT_NOTES')}
    />
  );
};
