import { styled } from '@mui/material/styles';

interface IJsonCell {
  data: object;
  hasDivider?: boolean;
}

interface IGetJsonCell {
  data: string;
  hasDivider?: boolean;
}

const JsonCellStyled = styled('div')<{ hasDivider?: boolean }>(
  ({ hasDivider = false }) => ({
    borderBottom: hasDivider ? '1px solid #ddd' : 'none',
    display: 'grid',
    fontFamily: 'HeyLight Sans',
    fontSize: '13px',
    gridTemplateColumns: 'repeat(auto-fit, minmax(350px, 1fr))',
    gap: '8px',
    marginTop: '20px',
    paddingBottom: hasDivider ? '20px' : 'none',
    whiteSpace: 'pre-wrap',
    wordWrap: 'break-word',
  }),
);

const JsonKeyStyled = styled('div')(() => ({
  color: 'grey',
  marginBottom: '7px',
}));

const JsonValueStyled = styled('div')(() => ({
  color: 'black',
  marginBottom: '20px',
}));

export const isJsonParsable = (jsonString: string) => {
  try {
    JSON.parse(jsonString);
    return true;
  } catch (e) {
    return false;
  }
};

export const JsonCell = ({ data, hasDivider = false }: IJsonCell) => {
  const entries = Object.entries(data)
    .sort(([keyA], [keyB]) => keyA.localeCompare(keyB))
    .map(([key, value]) => {
      const formattedValue =
        typeof value === 'object' && value !== null
          ? JSON.stringify(value, null, 2)
          : value;

      // Covert to string here to catch boolean types
      return { key, value: formattedValue.toString() };
    });

  return (
    <JsonCellStyled hasDivider={hasDivider}>
      {entries.map(({ key, value }) => {
        const isValidString = !['', undefined, null].includes(value);
        return isValidString ? (
          <div key={key}>
            <JsonKeyStyled>{key}</JsonKeyStyled>
            <JsonValueStyled>{value}</JsonValueStyled>
          </div>
        ) : null;
      })}
    </JsonCellStyled>
  );
};

export const normaliseJsonString = (jsonString: string) =>
  jsonString
    .replace(/'/g, '"')
    .replace(/\bTrue\b/g, 'true')
    .replace(/\bFalse\b/g, 'false');

export const getJsonParsedData = (str: string): object => {
  if (isJsonParsable(str)) {
    return JSON.parse(str);
  }

  const normalisedJson = normaliseJsonString(str);
  if (isJsonParsable(normalisedJson)) {
    return JSON.parse(normalisedJson);
  }
  return {};
};

export const getJsonCell = ({ data, hasDivider = false }: IGetJsonCell) => {
  if (isJsonParsable(data)) {
    return <JsonCell data={JSON.parse(data)} hasDivider={hasDivider} />;
  }

  const normalisedJson = normaliseJsonString(data);
  if (isJsonParsable(normalisedJson)) {
    return (
      <JsonCell data={JSON.parse(normalisedJson)} hasDivider={hasDivider} />
    );
  }
  return data;
};
