import styled from 'styled-components';
import { ToCamelCase } from '@heidi-pay/heidi-common-fe/types';
import { IStaticTableProps, StaticTable } from '@components/Table/StaticTable';
import { useI18n } from '@hooks/useI18n/useI18n';
import { IOpsPortalMerchantConfigs } from '@schemas/opsPortal/types/merchantConfigs';
import { IOrderProfile } from '@schemas/opsPortal/types/merchantDetails';
import { mapOrderProfileData } from '../../mapOrderProfileData';
import { IOrderProfileTableData } from '../../types';
import { getOrderProfileTableColumns } from './getOrderProfileTableColumns';

interface IOrderProfileTable {
  configs?: IOpsPortalMerchantConfigs;
  orderProfiles: ToCamelCase<IOrderProfile>[];
}

const StyledTable = styled(
  (props: IStaticTableProps<IOrderProfileTableData, unknown>) => (
    <StaticTable {...props} />
  ),
)({
  marginTop: '10px',
  td: { whiteSpace: 'nowrap' },
  th: { minWidth: '110px' },
});

export const OrderProfileTable = ({
  configs,
  orderProfiles,
}: IOrderProfileTable) => {
  const { getLocaleCurrencyValue, translate } = useI18n();

  const data = mapOrderProfileData({
    orderProfiles,
    translate,
    getLocaleCurrencyValue,
    configs,
  });

  const columns = getOrderProfileTableColumns(translate);

  return <StyledTable columns={columns} data={data} />;
};
