import { useCallback, useState } from 'react';
import { styled } from '@mui/material';
import { Row } from '@tanstack/react-table';
import { Button } from '@components/Button';
import { Translate } from '@hooks/useI18n';
import { HeyLightBranding } from '@utils/constants';
import { getJsonCell, getJsonParsedData } from '@utils/jsonUtils/jsonUtils';
import { CrabbStatus } from './enums';
import { ICrabbTableData, IRequestDataJson, IResponseDataJson } from './types';
import { getActionLabel } from './utils/getActionLabel';
import { getRequestDate } from './utils/getRequestDate';
import { getTranslationKey } from './utils/getTranslationKey';

interface IExpandableCrabbData {
  row: Row<ICrabbTableData>;
  translate: Translate;
}

export const ShowMoreLessButtonStyled = styled(Button)({
  color: HeyLightBranding.primaryColour,
  textDecoration: 'underline',
});

export const ExpandableCrabbData = ({
  row,
  translate,
}: IExpandableCrabbData) => {
  const [showRequestData, setShowRequestData] = useState(false);
  const { rawRequestData, rawResponseData, salesforceId, salesforceModel } =
    row.original;
  const responseDataJson: IResponseDataJson =
    getJsonParsedData(rawResponseData);
  const requestDataJson: IRequestDataJson = getJsonParsedData(rawRequestData);
  const { created, errors = [], success } = responseDataJson ?? {};

  const actionLabel: CrabbStatus = getActionLabel({ created, success });

  const requestDate = getRequestDate({
    requestData: requestDataJson,
    status: actionLabel,
    translate,
  });

  const handleOnClick = useCallback(() => {
    setShowRequestData(previous => !previous);
  }, []);

  return (
    <div>
      <p>
        <b>
          {requestDate}: {translate(getTranslationKey(actionLabel))}
        </b>{' '}
        - {salesforceModel} ({salesforceId})
      </p>
      {errors.length ? (
        <p>
          <b>{translate('OPS_PORTAL.LABEL.ERRORS')}:</b> {errors.join(', ')}
        </p>
      ) : null}
      {showRequestData ? (
        <>
          {getJsonCell({ data: rawRequestData })}
          <ShowMoreLessButtonStyled onClick={handleOnClick}>
            {translate('OPS_PORTAL.BUTTON.HIDE_REQUEST_DATA')}
          </ShowMoreLessButtonStyled>
        </>
      ) : (
        <ShowMoreLessButtonStyled onClick={handleOnClick}>
          {translate('OPS_PORTAL.BUTTON.SHOW_REQUEST_DATA')}
        </ShowMoreLessButtonStyled>
      )}
    </div>
  );
};
