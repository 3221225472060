import { Nullable } from '@heidi-pay/heidi-common-fe/types';
import { sort } from '@utils/sort';

export const sortDateString = (
  dateA: Nullable<string>,
  dateB: Nullable<string>,
): number => {
  const timeA = dateA ? new Date(dateA).getTime() : 0;
  const timeB = dateB ? new Date(dateB).getTime() : 0;
  return sort(Number.isNaN(timeA) ? 0 : timeA, Number.isNaN(timeB) ? 0 : timeB);
};
