/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable max-len */
import { ReactNode } from 'react';
import { GridRowModel, GridValidRowModel } from '@mui/x-data-grid';
import { missingValuePlaceholder } from '@utils/constants';

interface ICreateColumnParams<TData extends GridValidRowModel> {
  defaultEmptyValue?: string | number;
  // eslint-disable-next-line @typescript-eslint/ban-types
  field: keyof TData | (string & {});
  flexWidth?: number;
  label: string;
  // use mapCustomJSX to embed components into the cell
  mapCustomJSX?: (params: { row: GridRowModel<TData> }) => ReactNode;
  // use mapCustomValue to grab specific attributes from data and potentially format them
  mapCustomValue?: (row: GridRowModel) => string;
  sortComparator?: (valA: string, valB: string) => number;
  sortable?: boolean;
}

export type TableColumns<TData extends GridValidRowModel> = Array<
  ICreateColumnParams<TData>
>;

export interface ICreateTableColumnsV2<TData extends GridValidRowModel> {
  columns: TableColumns<TData>;
}

// If the name of the field matches to the name of the field in the data provided
// And the field is a simple data type i.e. string / number it will automatically
// be mapped to the column and display accordingly
export const createTableColumnsV2 = <TData extends GridValidRowModel>({
  columns,
}: ICreateTableColumnsV2<TData>) => {
  return columns.map((column: ICreateColumnParams<TData>) => {
    const {
      defaultEmptyValue = missingValuePlaceholder,
      field,
      flexWidth = 1,
      label,
      mapCustomJSX,
      mapCustomValue,
      sortable = false,
      sortComparator,
    } = column;

    const baseColumn = {
      field,
      filterable: false, // This disables MUI's built-in filtering system
      flex: flexWidth,
      headerName: label,
      sortable, // When sortable, the field name should match the backend's sorting field
      renderCell: undefined,
      valueGetter: undefined,
      valueFormatter: undefined,
      ...(sortComparator ? { sortComparator } : {}),
    };

    if (mapCustomJSX) {
      return {
        ...baseColumn,
        renderCell: (params: { row: GridRowModel<TData> }) =>
          mapCustomJSX(params) || defaultEmptyValue,
      };
    }

    if (mapCustomValue) {
      return {
        ...baseColumn,
        valueGetter: (_: unknown, row: GridRowModel<TData>) =>
          mapCustomValue({ row }) || defaultEmptyValue,
      };
    }

    return {
      ...baseColumn,
      valueFormatter: (value: string) => value || defaultEmptyValue,
    };
  });
};
