import { TableLimitOffsetV2 } from '@components/TableV2/TableLimitOffsetV2';
import { useI18n } from '@hooks/useI18n/useI18n';
import { TableNames } from '@utils/constants';
import { allAgencyNames } from './constants';
import { useGetAgencyAssignmentsTableConfig } from './useGetAgencyAssignmentsTableConfig';
import { getTotalBucketCountsPerDate } from './utils/getTotalBucketCountsPerDate';
import { getTotalBucketCountsPerDatePerAgency } from './utils/getTotalBucketCountsPerDatePerAgency';

export const AgencyAssignments = () => {
  const { translate } = useI18n();

  const {
    columns,
    data: agencyAssignmentsData,
    error,
    filtersSettings,
    hasDates,
    isLoading,
    paginationSettings,
    sortingSettings,
    toggleSettings,
  } = useGetAgencyAssignmentsTableConfig({ translate });

  const numberOfBuckets = Object.keys(agencyAssignmentsData).length;
  const numberOfAgencies = allAgencyNames.length;
  const aggregatedAssignmentData = getTotalBucketCountsPerDate(
    agencyAssignmentsData,
  );
  const aggregatedAssignmentDataByAgency = getTotalBucketCountsPerDatePerAgency(
    agencyAssignmentsData,
  );
  const data = toggleSettings?.value
    ? aggregatedAssignmentDataByAgency
    : aggregatedAssignmentData;
  const count = toggleSettings?.value
    ? numberOfAgencies * numberOfBuckets
    : numberOfBuckets;

  return (
    <TableLimitOffsetV2
      columns={columns}
      count={count}
      data={hasDates ? data : []}
      error={error}
      filterSettings={filtersSettings}
      isLoading={isLoading}
      isStatic={true}
      name={TableNames.AGENCY_ASSIGMENTS}
      paginationSettings={paginationSettings}
      sortingSettings={sortingSettings}
      title={translate('OPS_PORTAL.TITLE.AGENCY_ASSIGNMENTS')}
      toggleSettings={toggleSettings}
    />
  );
};
