import { useCallback } from 'react';
import axios, { AxiosError } from 'axios';
import { useToast } from '@heidi-pay/heidi-common-fe/hooks';
import { IError } from '@heidi-pay/heidi-common-fe/types';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useI18n } from '@hooks/useI18n/useI18n';
import { useModal } from '@hooks/useModal';
import { ModalStatusRouter } from '@hooks/useModal/components';
import { getModalDetails } from '@hooks/useModal/getModalDetails';
import { ISharedModalProps, Modal } from '@hooks/useModal/types';
import { IOpsPortalUserPermission } from '@schemas/opsPortal/types/accountSettings';
import {
  QueryKeys,
  MerchantUserDocumentClassifications,
  ContractUserDocumentClassifications,
} from '@utils/constants';
import { Endpoints } from '@utils/enums';
import { OpsUserDocumentUploadForm } from './OpsUserDocumentUploadForm';
import { IUserDocumentUploadData } from './types';

interface IOpsUserDocumentUploadModalProps extends ISharedModalProps {
  targetUuid?: string;
}

interface IOpsUserDocumentUploadModalSubProps
  extends IOpsUserDocumentUploadModalProps {
  classifications: Record<string, string>;
  endpoint: string;
  permission: IOpsPortalUserPermission;
}

export const useOpsUserDocumentUploadModal = (
  props: IOpsUserDocumentUploadModalSubProps,
): Modal => {
  const { classifications, endpoint, permission, targetUuid } = props;
  const { translate } = useI18n();
  const { message } = useToast(translate);
  const queryClient = useQueryClient();

  const modal = useModal({
    sharedProps: props,
    modalName: 'opsUserDocumentUploadModal',
    buttonTranslationKey: 'OPS_PORTAL.TITLE.UPLOAD_DOCUMENT',
    permissions: [permission],
  });
  const { closeModal, formStatusMessage } = modal;

  const formID = 'ops-user-document-upload';

  const { isLoading, mutate } = useMutation<
    void,
    AxiosError<IError>,
    IUserDocumentUploadData
  >(
    async input => {
      const formData = new FormData();
      formData.append('files', input.files[0], input.files[0].name);
      formData.append('name', input.name);
      formData.append('classification', input.classification);

      const url = `/api/${endpoint}/${targetUuid}/`;
      const { data } = await axios.post(url, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      return data;
    },
    {
      onError: () => message.error('ERROR.FILE_UPLOAD', { timeout: 10000 }),
      onSuccess: () => {
        message.success('GENERAL.FILE_UPLOAD_SUCCESS', { noTimeout: true });

        queryClient.invalidateQueries([
          QueryKeys.CONTRACT_DOCUMENTS_OPS_USER_DOCUMENTS,
        ]);
        queryClient.invalidateQueries([
          QueryKeys.DOCUMENT_OPS_MERCHANT_DOCUMENTS,
        ]);

        closeModal();
      },
    },
  );

  const handleFileUpload = useCallback(data => mutate(data), [mutate]);

  const modalContent = (
    <ModalStatusRouter
      formStatusMessage={formStatusMessage}
      isLoading={isLoading}
    >
      <OpsUserDocumentUploadForm
        classifications={classifications}
        formID={formID}
        handleSubmit={handleFileUpload}
      />
    </ModalStatusRouter>
  );

  const modalProps = {
    formID,
    isLoading,
    modalContent,
    title: translate('OPS_PORTAL.TITLE.UPLOAD_DOCUMENT'),
  };

  return getModalDetails({ modal, modalProps });
};

export const useOpsUserContractDocumentUploadModal = (
  props: IOpsUserDocumentUploadModalProps,
) =>
  useOpsUserDocumentUploadModal({
    ...props,
    endpoint: Endpoints.UploadOpsUserDocument,
    permission: 'ops_portal.can_view_customer_agreements',
    classifications: ContractUserDocumentClassifications,
  });

export const useOpsUserMerchantDocumentUploadModal = (
  props: IOpsUserDocumentUploadModalProps,
) =>
  useOpsUserDocumentUploadModal({
    ...props,
    endpoint: Endpoints.UploadOpsMerchantDocument,
    permission: 'ops_portal.can_view_merchant_documents',
    classifications: MerchantUserDocumentClassifications,
  });
