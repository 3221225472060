import { addPlaceholderForEmptyValues } from '@heidi-pay/heidi-common-fe/utils/addPlaceholderForEmptyValues';
import { ICrabbResults } from '@schemas/opsPortal/types/crabb';
import { ICrabbTableData } from './types';

interface IMapCrabbTableData {
  data?: ICrabbResults;
}

export const mapCrabbTableData = ({ data }: IMapCrabbTableData) =>
  data?.map(dataRow => {
    const {
      raw_request_data: rawRequestData,
      raw_response_data: rawResponseData,
      salesforce_id: salesforceId,
      salesforce_model: salesforceModel,
    } = dataRow;

    return addPlaceholderForEmptyValues<ICrabbTableData>({
      salesforceId,
      salesforceModel,
      rawRequestData,
      rawResponseData,
    });
  }) ?? [];
