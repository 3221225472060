import { useSelector } from 'react-redux';
import { AxiosError } from 'axios';
import { useQuery } from '@tanstack/react-query';
import { CommunicationIframe } from '@components/Common.styles';
import { QueryStateRouter } from '@components/QueryStateRouter';
import { useI18n } from '@hooks/useI18n/useI18n';
import { IOpsPortalConsumerCommunicationEvent } from '@schemas/opsPortal/types/consumerCommunicationEvent';
import { IUuid } from '@schemas/opsPortal/types/consumerDetails';
import { selectTimeToAuthExpiry } from '@selectors/index';
import { QueryKeys } from '@utils/constants';
import { Endpoints } from '@utils/enums';
import { fetchDataFromEndpoint } from '@utils/fetchDataFromEndpoint';
import { getDisplayData } from '@utils/getDisplayData';

interface IEventViewProps {
  selectedEventUUID: IUuid;
}

export const EventView = ({ selectedEventUUID }: IEventViewProps) => {
  const { translate } = useI18n();

  const msUntilTokenExpiry = useSelector(selectTimeToAuthExpiry);

  const {
    data,
    error,
    isInitialLoading: isLoading,
  } = useQuery<IOpsPortalConsumerCommunicationEvent, AxiosError>(
    [QueryKeys.COMMUNICATIONS_EVENT, selectedEventUUID],
    () =>
      fetchDataFromEndpoint(
        `${Endpoints.FetchConsumerEvent}/${selectedEventUUID}`,
      ),
    {
      cacheTime: msUntilTokenExpiry,
      enabled: Boolean(selectedEventUUID),
      staleTime: msUntilTokenExpiry,
    },
  );

  return (
    <QueryStateRouter error={error} isLoading={isLoading}>
      {data ? (
        <CommunicationIframe src={getDisplayData(data)} />
      ) : (
        <p>
          {translate(
            'OPS_PORTAL.PAGE.COMMUNICATIONS.NO_COMMUNICATION_SELECTED',
          )}
        </p>
      )}
    </QueryStateRouter>
  );
};
