import { Link } from 'react-router-dom';
import { createColumnHelper } from '@tanstack/react-table';
import { Translate } from '@hooks/useI18n';
import { IConsumersTableData } from './types';

export const getConsumerTableColumns = (translate: Translate) => {
  const columnHelper = createColumnHelper<IConsumersTableData>();

  return [
    columnHelper.accessor('consumerEmail', {
      header: translate('LABEL.EMAIL_ADDRESS'),
      cell: ({ row }) => (
        <Link to={row.original.consumerUUID}>{row.original.consumerEmail}</Link>
      ),
    }),
    columnHelper.accessor('consumerFirstName', {
      header: translate('LABEL.FIRST_NAME'),
    }),
    columnHelper.accessor('consumerLastName', {
      header: translate('LABEL.LAST_NAME'),
    }),
    columnHelper.accessor('createdDate', {
      header: translate('OPS_PORTAL.TITLE.CREATED_DATE'),
    }),
  ];
};
