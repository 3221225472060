import { addPlaceholderForEmptyValues } from '@heidi-pay/heidi-common-fe/utils/addPlaceholderForEmptyValues';
import { GetLocaleDate, Translate } from '@hooks/useI18n';
import { IConsumerCommunicationEvents } from '@schemas/opsPortal/types/consumerCommunicationEvents';
import { ICommunicationsTableData } from '../types';

interface IPrepareCommunicationsTableDataProps {
  data: IConsumerCommunicationEvents;
  getLocaleDate: GetLocaleDate;
  translate: Translate;
}

const dict = {
  email: 'OPS_PORTAL.PAGE.COMMUNICATIONS.EMAIL',
  text_message: 'OPS_PORTAL.PAGE.COMMUNICATIONS.TEXT_MESSAGE',
};

export const prepareCommunicationsTableData = ({
  data,
  getLocaleDate,
  translate,
}: IPrepareCommunicationsTableDataProps): ICommunicationsTableData[] =>
  data.map(dataRow => {
    const {
      channel: communicationType,
      sent_at: sentDate,
      uuid: eventUUID,
    } = dataRow;

    return addPlaceholderForEmptyValues<ICommunicationsTableData>({
      communicationType,
      communicationTypeLabel:
        communicationType &&
        translate(
          dict[communicationType as keyof typeof dict] || communicationType,
        ),
      eventUUID,
      sentDate:
        sentDate && getLocaleDate({ date: sentDate, includeTime: true }),
    });
  });
