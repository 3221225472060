import { Translate } from '@hooks/useI18n';
import { CrabbStatus } from '../enums';
import { IRequestDataJson } from '../types';

interface IGetRequestDate {
  requestData: IRequestDataJson;
  status: string;
  translate: Translate;
}

export const getRequestDate = ({
  requestData,
  status,
  translate,
}: IGetRequestDate) => {
  const {
    Created_Date__c: createdDate,
    Dates_Instalments_Unpaid_In_Arrears_Arr__c: dateInstallmentUnpaid,
    Registration_Date__c: registrationDate,
  } = requestData;
  const createdDateRegistationFallback = createdDate ?? registrationDate;
  const installmentUnpaidDateFallback =
    createdDateRegistationFallback ?? dateInstallmentUnpaid;

  const statusToDateMapping: Record<string, string | undefined> = {
    [CrabbStatus.CREATED]: installmentUnpaidDateFallback,
    [CrabbStatus.FAILED]: installmentUnpaidDateFallback,
    [CrabbStatus.UNKNOWN]: installmentUnpaidDateFallback,
    [CrabbStatus.UPDATED]: dateInstallmentUnpaid,
  };

  return (
    statusToDateMapping[status]?.replaceAll("'", '') ??
    translate('OPS_PORTAL.LABEL.DATE_UNKNOWN')
  );
};
