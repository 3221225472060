import { ColumnDef, createColumnHelper } from '@tanstack/react-table';
import { SkeletonColumnCell } from '@components/SkeletonColumnCell';
import { Translate } from '@hooks/useI18n';
import { IOrderProfileTableData } from '@pages/MerchantDetails/types';

export const getOrderProfileTableColumns = (translate: Translate) => {
  const columnHelper = createColumnHelper<IOrderProfileTableData>();

  return [
    columnHelper.accessor('orderProfileUuid', {
      header: translate('OPS_PORTAL.LABEL.ORDER_PROFILE_UUID'),
    }),
    columnHelper.accessor('name', {
      header: translate('OPS_PORTAL.LABEL.NAME'),
    }),
    columnHelper.accessor('productName', {
      header: translate('OPS_PORTAL.LABEL.PRODUCT_NAME'),
    }),
    columnHelper.accessor('subProductName', {
      header: translate('OPS_PORTAL.LABEL.SUBPRODUCT_NAME'),
    }),
    columnHelper.accessor('externalOriginatorProfileId', {
      header: translate('OPS_PORTAL.LABEL.EXTERNAL_ORIGINATOR_PROFILE_ID'),
    }),
    columnHelper.accessor('installmentMode', {
      header: translate('OPS_PORTAL.LABEL.INSTALLMENT_MODE'),
    }),
    columnHelper.accessor('delayFinalisation', {
      header: translate('OPS_PORTAL.LABEL.DELAY_FINALISATION'),
    }),
    columnHelper.accessor('awaitingDispatchTimeout', {
      header: translate('OPS_PORTAL.LABEL.AWAITING_DISPATCH_TIMEOUT'),
    }),
    columnHelper.accessor('routingLowerLimit', {
      header: translate('OPS_PORTAL.LABEL.ROUTING_LOWER_LIMIT'),
    }),
    columnHelper.accessor('routingUpperLimit', {
      header: translate('OPS_PORTAL.LABEL.ROUTING_UPPER_LIMIT'),
    }),
    columnHelper.accessor('maxContractBalanceLimit', {
      header: translate('OPS_PORTAL.LABEL.MAX_CONTRACT_BALANCE_LIMIT'),
    }),
    columnHelper.accessor('dynamicDepositEnabled', {
      header: translate('OPS_PORTAL.LABEL.DYNAMIC_DEPOSIT_ENABLED'),
    }),
    columnHelper.accessor('numberOfInstallmentsChoices', {
      header: translate('OPS_PORTAL.LABEL.NUMBER_OF_INSTALLMENTS_CHOICES'),
    }),
    columnHelper.accessor('minimumRiskAcceptanceLevel', {
      header: translate('OPS_PORTAL.LABEL.MINIMUM_RISK_ACCEPTANCE_LEVEL'),
    }),
    columnHelper.accessor('delayFinalInstallment', {
      header: translate('OPS_PORTAL.LABEL.DELAY_FINAL_INSTALLMENT'),
    }),
    columnHelper.accessor('totalExposurePerClient', {
      header: translate('OPS_PORTAL.LABEL.TOTAL_EXPOSURE_PER_CLIENT'),
    }),
    columnHelper.accessor('riskConfigName', {
      header: translate('OPS_PORTAL.LABEL.RISK_CONFIG_NAME'),
      cell: ({ row }) => (
        <SkeletonColumnCell value={row.original.riskConfigName} />
      ),
    }),
    columnHelper.accessor('waterfallConfigName', {
      header: translate('OPS_PORTAL.LABEL.WATERFALL_CONFIG_NAME'),
      cell: ({ row }) => (
        <SkeletonColumnCell value={row.original.waterfallConfigName} />
      ),
    }),
    columnHelper.accessor('feeConfig', {
      header: translate('OPS_PORTAL.LABEL.FEE_CONFIG'),
    }),
    columnHelper.accessor('commissionConfig', {
      header: translate('OPS_PORTAL.LABEL.COMMISSION_CONFIG'),
    }),
    columnHelper.accessor('paymentScheduleConfig', {
      header: translate('OPS_PORTAL.LABEL.PAYMENT_SCHEDULE_CONFIG'),
    }),
    columnHelper.accessor('contractType', {
      header: translate('OPS_PORTAL.LABEL.CONTRACT_TYPE'),
    }),
    columnHelper.accessor('isManualAgreementSigning', {
      header: translate('OPS_PORTAL.LABEL.IS_MANUAL_AGREEMENT_SIGNING'),
    }),
    columnHelper.accessor('autoDispatchOnManualAgreementSigningComplete', {
      header: translate(
        'OPS_PORTAL.LABEL.AUTO_DISPATCH_ON_MANUAL_AGREEMENT_SIGNING_COMPLETE',
      ),
    }),
    columnHelper.accessor('communicationConfiguration', {
      header: translate('OPS_PORTAL.LABEL.COMMUNICATION_CONFIGURATION'),
    }),
    columnHelper.accessor('productGroup', {
      header: translate('OPS_PORTAL.LABEL.PRODUCT_GROUP'),
    }),
  ] as ColumnDef<IOrderProfileTableData, unknown>[];
};
