import { IAmountObject } from '@schemas/opsPortal/types/contractDetails';

export const getSystemRemainder = (
  totalBalance?: IAmountObject,
  totalMovement?: IAmountObject,
) => {
  if (!totalBalance || !totalMovement) {
    return undefined;
  }

  const systemRemainderAmount = (
    parseFloat(totalBalance.amount) - parseFloat(totalMovement.amount)
  ).toFixed(2);

  return {
    amount: systemRemainderAmount,
    currency: totalBalance?.currency,
  } as IAmountObject;
};
