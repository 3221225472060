import { AxiosError } from 'axios';
import { useQuery } from '@tanstack/react-query';
import { IOpsPortalMerchantNotes } from '@schemas/opsPortal/types/merchantNotes';
import { QueryKeys } from '@utils/constants';
import { Endpoints } from '@utils/enums';
import { fetchDataFromEndpoint } from '@utils/fetchDataFromEndpoint/fetchDataFromEndpoint';

interface IUseGetMerchantNotes {
  uuid?: string;
}

export const useGetMerchantNotesQuery = ({ uuid }: IUseGetMerchantNotes) => {
  const {
    data: merchantNotesData,
    error: merchantNotesError,
    isLoading: isMerchantNotesLoading,
  } = useQuery<IOpsPortalMerchantNotes, AxiosError>(
    [QueryKeys.MERCHANT_NOTES, uuid],
    () => fetchDataFromEndpoint(`${Endpoints.FetchMerchantNotes}/${uuid}`),
    { enabled: !!uuid },
  );

  return {
    merchantNotesData,
    merchantNotesError,
    isMerchantNotesLoading,
  };
};
