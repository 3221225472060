import { IRiskData } from '@schemas/opsPortal/types/applicationDetails';
import { missingValuePlaceholder } from '@utils/constants';

export const mapRiskTestsData = (data: IRiskData) => {
  const mappedTestBreakdowns = data.tests_breakdown?.map(test => {
    const {
      outcome: testOutcome,
      result: { result: rawTestResult },
      test_name: testName,
    } = test;

    const isValidResultValue =
      rawTestResult !== null &&
      rawTestResult !== undefined &&
      typeof rawTestResult !== 'object';
    if (!isValidResultValue) {
      return { testName, testOutcome, testResult: missingValuePlaceholder };
    }

    const isValidString = rawTestResult.toString().trim().length > 0;
    const testResult = isValidString ? rawTestResult : missingValuePlaceholder;
    return { testName, testOutcome, testResult };
  });

  return mappedTestBreakdowns
    ? mappedTestBreakdowns.sort((test1, test2) =>
        test1.testName.localeCompare(test2.testName),
      )
    : [];
};
