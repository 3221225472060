import { Permissions } from '@appTypes';
import { PageRoutes } from '@utils/enums';
import { scopes } from '@utils/scopes';
import { BankingNavItems } from './NavItems/BankingNavItems';
import { ContractNavItems } from './NavItems/ContractNavItems';

interface INavItem {
  iconPath: string;
  link: string;
  scopes: Permissions;
  target?: '_blank';
  translationKey: string;
}

export interface INavItems {
  [key: string]: INavItem;
}

const svgOrdersBlocks = '/media/svg/icons/Layout/Layout-4-blocks.svg';

export const NavItems: INavItems = Object.freeze({
  ACCOUNT_SETTINGS: {
    iconPath: '/media/svg/icons/Code/Settings4.svg',
    link: PageRoutes.AccountSettings,
    scopes: [
      'consumer_portal.change_consumeruser',
      'merchant_portal.can_view_account_info',
      'ops_portal.view_merchant',
    ],
    translationKey: 'TITLE.ACCOUNT_SETTINGS',
  },
  API_CALLS: {
    iconPath: '/media/svg/icons/Navigation/Route.svg',
    link: PageRoutes.ApiCalls,
    scopes: ['ops_portal.view_checkout_api_requests'],
    translationKey: 'OPS_PORTAL.TITLE.API_CALLS',
  },
  APPLICATIONS: {
    iconPath: '/media/svg/icons/Communication/Clipboard-list.svg',
    link: PageRoutes.Applications,
    scopes: [
      'ops_portal.view_application',
      'merchant_portal.can_view_applications',
    ],
    translationKey: 'TITLE.APPLICATIONS',
  },
  AWAITING_CONFIRMATION: {
    iconPath: '/media/svg/icons/Code/Time-schedule.svg',
    link: PageRoutes.AwaitingConfirmation,
    scopes: ['merchant_portal.can_view_awaiting_dispatch_orders'],
    translationKey: 'STATUS.AWAITING_CONFIRMATION',
  },
  BLOCKED_APPLICANTS: {
    iconPath: '/media/svg/icons/Tools/Road-Cone.svg',
    link: PageRoutes.BlockedApplicants,
    scopes: ['ops_portal.can_view_blocked_applicants'],
    translationKey: 'OPS_PORTAL.TITLE.BLOCKED_APPLICANTS',
  },
  CARD_CHANGE_GUIDE: {
    iconPath: '/media/svg/icons/Code/Question-circle.svg',
    link: PageRoutes.CardChangeGuide,
    scopes: ['consumer_portal.can_change_card'],
    target: '_blank',
    translationKey: 'TITLE.CARD_CHANGE_GUIDE',
  },
  CONSUMERS: {
    iconPath: '/media/svg/icons/Communication/Group.svg',
    link: PageRoutes.Consumers,
    scopes: ['ops_portal.view_consumer'],
    translationKey: 'OPS_PORTAL.TITLE.CONSUMERS',
  },
  CREATE_NEW_PLAN: {
    iconPath: '/media/svg/icons/Code/Compiling.svg',
    link: PageRoutes.CreateNewPlan,
    scopes: ['merchant_portal.can_create_a_new_plan'],
    translationKey: 'TITLE.CREATE_NEW_PLAN',
  },
  DOCUMENTS: {
    iconPath: '/media/svg/icons/Files/Folder.svg',
    link: PageRoutes.Documents,
    scopes: ['consumer_portal.can_view_documents'],
    translationKey: 'TITLE.DOCUMENTS',
  },
  LOGOUT: {
    iconPath: '/media/svg/icons/Navigation/Sign-out.svg',
    link: PageRoutes.Logout,
    scopes: scopes.all,
    translationKey: 'TITLE.LOGOUT',
  },
  MERCHANT_GUIDE: {
    iconPath: '/media/svg/icons/General/Visible.svg',
    link: PageRoutes.MerchantGuide,
    scopes: ['merchant_portal.can_view_merchant_guide'],
    target: '_blank',
    translationKey: 'TITLE.MERCHANT_GUIDE',
  },
  MERCHANT_STORES: {
    iconPath: '/media/svg/icons/Home/Home.svg',
    link: PageRoutes.MerchantStores,
    scopes: ['merchant_portal.can_view_merchant_stores'],
    translationKey: 'TITLE.MERCHANT_STORES',
  },
  MERCHANT_PRIVACY_POLICY: {
    iconPath: '/media/svg/icons/Communication/Shield-user.svg',
    link: PageRoutes.MerchantPrivacyPolicy,
    scopes: scopes.merchant,
    target: '_blank',
    translationKey: 'TITLE.MERCHANT_PRIVACY_POLICY',
  },
  MERCHANTS: {
    iconPath: '/media/svg/icons/Shopping/Box2.svg',
    link: PageRoutes.Merchants,
    scopes: ['ops_portal.view_merchant'],
    translationKey: 'OPS_PORTAL.TITLE.MERCHANTS',
  },
  MY_APPLICATIONS: {
    iconPath: '/media/svg/icons/Communication/Clipboard-list.svg',
    link: PageRoutes.MyApplications,
    scopes: ['merchant_portal.can_view_sales_assistant_limited_access'],
    translationKey: 'TITLE.MY_APPLICATIONS',
  },
  MY_ORDERS: {
    iconPath: svgOrdersBlocks,
    link: PageRoutes.MyOrders,
    scopes: ['merchant_portal.can_view_sales_assistant_limited_access'],
    translationKey: 'TITLE.MY_ORDERS',
  },
  ORDERS: {
    iconPath: svgOrdersBlocks,
    link: PageRoutes.Orders,
    scopes: [
      'consumer_portal.can_view_payment_plans',
      'merchant_portal.can_view_payment_plans',
    ],
    translationKey: 'TITLE.ORDERS',
  },
  ORDERS_CONSUMER_PAGO: {
    iconPath: svgOrdersBlocks,
    link: PageRoutes.Orders,
    scopes: ['consumer_portal.can_view_payment_plans'],
    translationKey: 'TITLE.MY_ORDERS',
  },
  ORG_INFO: {
    iconPath: '/media/svg/icons/Shopping/Barcode-read.svg',
    link: PageRoutes.OrgInfo,
    scopes: ['merchant_portal.can_edit_merchant_settings'],
    translationKey: 'TITLE.ORG_INFO',
  },
  SETTLEMENTS: {
    iconPath: '/media/svg/icons/General/Settings-1.svg',
    link: PageRoutes.Settlements,
    scopes: ['merchant_portal.can_view_reconciliation'],
    translationKey: 'TITLE.SETTLEMENTS',
  },
  USERS: {
    iconPath: '/media/svg/icons/Communication/Group.svg',
    link: PageRoutes.Users,
    scopes: ['merchant_portal.can_edit_user_settings'],
    translationKey: 'TITLE.USERS',
  },
  ...BankingNavItems,
  ...ContractNavItems,
});
