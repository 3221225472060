/* eslint-disable max-len */
export enum OpsPortalDocumentTypes {
  CustomerAgreement = 'Customer agreement',
  IntrumIdentificationFiles = 'Intrum identification files',
  IntrumSigningFiles = 'Intrum signing files',
  OnfidoBack = 'Onfido back',
  OnfidoFront = 'Onfido front',
  OnfidoHealthCard = 'Onfido health card',
  OnfidoSelfie = 'Onfido selfie',
  TermsAndConditionsConclusion = 'Terms and conditions (Conclusion)',
  TermsAndConditionsFinalization = 'Terms and conditions (Finalization)',
  TradeInOptionAgreement = 'Trade in option agreement',
  UploadedDocumentAcknowledgementOfDebt = 'Uploaded document (Acknowledgement of debt)',
  UploadedDocumentAddressPhoneNumberChangeRequest = 'Uploaded document (Address/phone number change request)',
  UploadedDocumentCommunicationRelatedToAGoodOrServiceRefund = 'Uploaded document (Communication related to a good or service refund)',
  UploadedDocumentCommunicationRelatedToAMoneyRefund = 'Uploaded document (Communication related to a money refund)',
  UploadedDocumentCommunicationWithDebtCollectionAgencies = 'Uploaded document (Communication with debt collection agencies)',
  UploadedDocumentComplaintAboutAgency = 'Uploaded document (Complaint about debt collection agency)',
  UploadedDocumentComplaintAboutLateFee = 'Uploaded document (Complaint about late fee)',
  UploadedDocumentComplaintAboutMerchant = 'Uploaded document (Complaint about merchant)',
  UploadedDocumentCopyOfAProofOfPayment = 'Uploaded document (Copy of a proof of payment)',
  UploadedDocumentCopyOfTheInvoiceInitialOrder = 'Uploaded document (Copy of the invoice/initial order)',
  UploadedDocumentCreditContractSignedInstore = 'Uploaded document (Credit contract signed instore)',
  UploadedDocumentEarlyRepaymentRequest = 'Uploaded document (Early repayment request)',
  UploadedDocumentGeneralComplaint = 'Uploaded document (General complaint)',
  UploadedDocumentIbanForARefundSentByCustomer = 'Uploaded document (IBAN for a refund sent by the customer)',
  UploadedDocumentImportantCommunication = 'Uploaded document (Important communication from customer)',
  UploadedDocumentOther = 'Uploaded document (Other)',
  UploadedDocumentReplyToAComplaint = 'Uploaded document (Reply to a complaint)',
}

export enum OpsPortalDocumentModes {
  Contract = 'Contract',
  Merchant = 'Merchant',
}

export const UploadedDocumentTypes = [
  OpsPortalDocumentTypes.UploadedDocumentAcknowledgementOfDebt,
  OpsPortalDocumentTypes.UploadedDocumentComplaintAboutAgency,
  OpsPortalDocumentTypes.UploadedDocumentComplaintAboutLateFee,
  OpsPortalDocumentTypes.UploadedDocumentComplaintAboutMerchant,
  OpsPortalDocumentTypes.UploadedDocumentCreditContractSignedInstore,
  OpsPortalDocumentTypes.UploadedDocumentGeneralComplaint,
  OpsPortalDocumentTypes.UploadedDocumentImportantCommunication,
  OpsPortalDocumentTypes.UploadedDocumentOther,
  OpsPortalDocumentTypes.UploadedDocumentReplyToAComplaint,
  OpsPortalDocumentTypes.UploadedDocumentAddressPhoneNumberChangeRequest,
  OpsPortalDocumentTypes.UploadedDocumentCommunicationRelatedToAGoodOrServiceRefund,
  OpsPortalDocumentTypes.UploadedDocumentCommunicationRelatedToAMoneyRefund,
  OpsPortalDocumentTypes.UploadedDocumentCommunicationWithDebtCollectionAgencies,
  OpsPortalDocumentTypes.UploadedDocumentCopyOfAProofOfPayment,
  OpsPortalDocumentTypes.UploadedDocumentCopyOfTheInvoiceInitialOrder,
  OpsPortalDocumentTypes.UploadedDocumentEarlyRepaymentRequest,
  OpsPortalDocumentTypes.UploadedDocumentIbanForARefundSentByCustomer,
];

export const OnfidoDocumentTypes = [
  OpsPortalDocumentTypes.OnfidoFront,
  OpsPortalDocumentTypes.OnfidoBack,
  OpsPortalDocumentTypes.OnfidoSelfie,
  OpsPortalDocumentTypes.OnfidoHealthCard,
];

export const IntrumDocumentTypes = [
  OpsPortalDocumentTypes.IntrumIdentificationFiles,
  OpsPortalDocumentTypes.IntrumSigningFiles,
];

export const CustomerAgreementDocumentTypes = [
  OpsPortalDocumentTypes.CustomerAgreement,
  OpsPortalDocumentTypes.TradeInOptionAgreement,
];

export const TermsAndConditionsDocumentTypes = [
  OpsPortalDocumentTypes.TermsAndConditionsConclusion,
  OpsPortalDocumentTypes.TermsAndConditionsFinalization,
];
