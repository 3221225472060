import { TableLimitOffsetV2 } from '@components/TableV2/TableLimitOffsetV2';
import { useGetMerchantsQuery } from '@hooks/queries/useGetMerchantsQuery';
import { useI18n } from '@hooks/useI18n/useI18n';
import { useOriginator } from '@hooks/useOriginator/useOriginator';
import { usePortal } from '@hooks/usePortal/usePortal';
import { useGetCollectionsTableConfig } from './useGetCollectionsTableConfig';

export const CollectionsTableV2 = () => {
  const { translate } = useI18n();
  const { originator } = useOriginator();
  const { isOpsPortal } = usePortal();

  const { isMerchantsLoading, merchantsData, merchantsError } =
    useGetMerchantsQuery({ enabled: isOpsPortal, originator });

  const {
    columns,
    data: collectionsData,
    error: collectionsError,
    filtersSettings,
    isLoading: isCollectionsLoading,
    paginationSettings,
    sortingSettings,
  } = useGetCollectionsTableConfig({ merchantsData, translate });

  const isLoading = isMerchantsLoading || isCollectionsLoading;
  const error = merchantsError || collectionsError;

  return (
    <TableLimitOffsetV2
      columns={columns}
      count={collectionsData?.count ?? 10}
      data={collectionsData?.results ?? []}
      error={error}
      filterSettings={filtersSettings}
      isLoading={isLoading}
      name="Collections"
      paginationSettings={paginationSettings}
      sortingSettings={sortingSettings}
      title={translate('OPS_PORTAL.TITLE.COLLECTIONS')}
    />
  );
};
