import {
  TableColumns,
  createTableColumnsV2,
} from '@components/TableV2/helpers/createTableColumnsV2';
import {
  IBucketAssignmentCountData,
  IUseGetAgencyAssignmentsColumns,
} from './types';
import { getAgencyColumn } from './utils/getAgencyColumn';
import { getBucketColumn } from './utils/getBucketColumn';

export const useGetAgencyAssignmentsByAgencyBucketDays = ({
  dates,
  translate,
}: IUseGetAgencyAssignmentsColumns) => {
  const datesColumns: TableColumns<IBucketAssignmentCountData> = dates.map(
    date => ({
      field: date,
      label: date,
      mapCustomValue: ({ row }) => row.assignmentCounts?.[date] ?? 0,
    }),
  );

  const columns: TableColumns<IBucketAssignmentCountData> = [
    getAgencyColumn(translate),
    getBucketColumn(translate),
    ...datesColumns,
  ];

  return createTableColumnsV2({ columns });
};
