import { ColumnDef, createColumnHelper } from '@tanstack/react-table';
import { IActionButton, Portal } from '@appTypes';
import { Link } from '@components/Common.styles';
import { ConfirmDispatchButton } from '@components/ConfirmDispatchButton';
import { StatusPill } from '@components/StatusPill';
import { Translate } from '@hooks/useI18n';
import { ICustomTableColumns } from '@schemas/merchantPortal/types/accountSettings';
import { Portals } from '@utils/constants';
import { filterDateRange } from '@utils/filterDateRange';
import { getCustomTableColumns } from '@utils/getCustomTableColumns';
import { IOrderTableData } from './types';

const getPortalConfig = (
  portal: Portal,
  isAwaitingDispatch: boolean,
  merchantColumnConfig?: ICustomTableColumns,
): ICustomTableColumns => {
  const portalConfigs: Record<Portal, ICustomTableColumns> = {
    [Portals.CONSUMER]: [
      { dataKey: 'orderId' },
      { dataKey: 'merchantName' },
      { dataKey: 'merchantReference' },
      { dataKey: 'orderValue' },
      { dataKey: 'dateConfirmed' },
      { dataKey: 'status' },
    ],
    [Portals.MERCHANT]: merchantColumnConfig ?? [
      { dataKey: 'orderId' },
      { dataKey: 'merchantReference' },
      { dataKey: 'customerName' },
      { dataKey: 'orderValue' },
      { dataKey: 'dateConfirmed' },
      { dataKey: 'status' },
      { dataKey: 'originationChannel' },
    ],
    [Portals.OPS]: [],
  };

  if (portal === Portals.MERCHANT && isAwaitingDispatch) {
    return portalConfigs[Portals.MERCHANT]
      .filter(column => column.dataKey !== 'status')
      .concat({ dataKey: 'buttonColumn' });
  }

  return portalConfigs[portal];
};

interface IGetOrdersTableColumnsProps {
  button: IActionButton | undefined;
  isAwaitingDispatch: boolean;
  isCompass: boolean;
  isPagolightPro: boolean;
  merchantColumnConfig?: ICustomTableColumns;
  portal: Portal;
  setDispatchedUuid: (uuid: string) => void;
  translate: Translate;
}

export const getOrdersTableColumns = ({
  button,
  isAwaitingDispatch = false,
  isCompass = false,
  isPagolightPro,
  merchantColumnConfig,
  portal,
  setDispatchedUuid,
  translate,
}: IGetOrdersTableColumnsProps) => {
  const isMerchantPortal = portal === Portals.MERCHANT;
  const columnHelper = createColumnHelper<IOrderTableData>();
  const portalConfig = getPortalConfig(
    portal,
    isAwaitingDispatch,
    merchantColumnConfig,
  );

  const paymentPlanReference = columnHelper.accessor('paymentPlanReference', {
    header: translate('TITLE.PAYMENT_PLAN_ID'),
    cell: ({ row }) => (
      <Link to={row.original.orderId}>{row.original.paymentPlanReference}</Link>
    ),
  });

  const mediobancaExternalId = columnHelper.accessor('mediobancaExternalId', {
    header: translate('TITLE.EXTERNAL_ID'),
    cell: ({ row }) => (
      <Link to={row.original.orderId}>{row.original.mediobancaExternalId}</Link>
    ),
  });

  const getMerchantReferenceKey = () => {
    if (isMerchantPortal && !isPagolightPro) {
      return 'TITLE.YOUR_INVOICE_NUMBER';
    }
    return 'TITLE.INVOICE_NUMBER';
  };

  const defaultColumns: Partial<
    Record<keyof IOrderTableData, ColumnDef<IOrderTableData, string>>
  > = {
    orderId: isCompass ? mediobancaExternalId : paymentPlanReference,
    merchantName: columnHelper.accessor('merchantName', {
      header: translate('LABEL.MERCHANT_NAME'),
    }),
    merchantReference: columnHelper.accessor('merchantReference', {
      header: translate(getMerchantReferenceKey()),
    }),
    orderValue: columnHelper.accessor('orderValue', {
      header: translate(
        isPagolightPro
          ? 'PAGE.ORDERS.INITIAL_ORDER_VALUE'
          : 'PAGE.ORDERS.ORDER_VALUE',
      ),
    }),
    dateConfirmed: columnHelper.accessor('dateConfirmed', {
      header: translate('TITLE.DATE_CONFIRMED'),
      filterFn: (row, _, filterValue) =>
        filterDateRange({ accessorKey: 'dateRaw', filterValue, row }),
    }),
    status: columnHelper.accessor('status', {
      header: translate('TITLE.ORDER_STATUS'),
      cell: ({ row }) => (
        <StatusPill
          rawStatus={row.original.rawStatus}
          status={row.original.status}
        />
      ),
    }),
    customerName: columnHelper.accessor('customerName', {
      header: translate(
        isPagolightPro
          ? 'PAGOLIGHT_PRO.TITLE.CUSTOMER_NAME'
          : 'TITLE.CUSTOMER_NAME',
      ),
    }),
    originationChannel: columnHelper.accessor('originationChannel', {
      header: translate('TITLE.CHANNEL'),
      enableSorting: false,
    }),
    productSku: columnHelper.accessor('productSku', {
      header: translate('LABEL.SKU'),
      enableSorting: false,
    }),
    buttonColumn: columnHelper.display({
      cell: ({ row }) =>
        button && row.original.delayFinalisation === true ? (
          <ConfirmDispatchButton
            button={button}
            setDispatchedUuid={setDispatchedUuid}
            uuid={row.original.orderId}
          />
        ) : null,
      id: 'buttonColumn',
      enableSorting: false,
    }),
  };

  return getCustomTableColumns(portalConfig, defaultColumns, translate);
};
