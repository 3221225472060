import { AxiosError } from 'axios';
import { QueryKey, useQuery } from '@tanstack/react-query';
import { ValueOf } from '@appTypes';
import { IOpsPortalContractDetails } from '@schemas/opsPortal/types/contractDetails';
import { Views } from '@utils/constants';
import { ArrearsManagedByKeys, Endpoints } from '@utils/enums';
import { fetchDataFromEndpoint } from '@utils/fetchDataFromEndpoint';

interface IUseGetContractQuery {
  contractDataQueryKey: QueryKey;
  queryKeyContractUuid?: string;
  view?: ValueOf<typeof Views>;
}

export const useGetContractQuery = ({
  contractDataQueryKey,
  queryKeyContractUuid,
  view,
}: IUseGetContractQuery) => {
  const isOverpaidContract = view === Views.OVERPAID_CONTRACTS;

  const {
    data: contractData,
    error: contractDataError,
    isFetching: isContractDataFetching,
    isLoading: isContractDataLoading,
  } = useQuery<IOpsPortalContractDetails, AxiosError>(
    contractDataQueryKey,
    () =>
      fetchDataFromEndpoint(
        `${Endpoints.FetchContracts}/${queryKeyContractUuid}`,
        {
          query: isOverpaidContract ? 'is_contract_uuid=true' : '',
        },
      ),
  );

  const externalContractUuid = contractData?.contract_details?.external_uuid;
  const contractUuid = contractData?.contract_details?.contract_uuid;

  // If the contract is not assigned to anyone, currently the contractData.arrears_managed_by field
  // will default return Heidipay. There is work to deprecate the use of this field repo-wide and
  // instead use the collections API call data above which will return null if the contracts is not assigned
  // Ticket - https://heidi-pay.atlassian.net/browse/HC-11412
  const isArrearsToBeManagedByHeidi =
    contractData?.contract_details?.arrears_managed_by ===
    ArrearsManagedByKeys.HeidiPay;

  const contractDetails = contractData?.contract_details;
  const orderDetails = contractDetails?.order_details?.full_details;

  return {
    contractData,
    contractDataError,
    contractDetails,
    contractUuid,
    externalContractUuid,
    isArrearsToBeManagedByHeidi,
    isContractDataFetching,
    isContractDataLoading,
    orderDetails,
  };
};
