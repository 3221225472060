/* eslint-disable max-nested-callbacks */
import {
  IAssignmentsCount,
  IOpsPortalAggregateAgencyAssignments,
} from '@schemas/opsPortal/types/aggregateAgencyAssignments';
import { allBucketNames } from '../constants';
import { IBucketAssignmentCountData } from '../types';

export const getTotalBucketCountsPerDate = (
  data: IOpsPortalAggregateAgencyAssignments,
): IBucketAssignmentCountData[] => {
  const totalBucketCountsPerDate: Record<string, Record<string, number>> = {};
  const allDates = new Set<string>();

  const bucketEntries = Object.entries(data);
  bucketEntries.forEach(([bucket, dates]) => {
    const dateEntries = Object.entries(dates);
    dateEntries.forEach(([date, assignments]) => {
      allDates.add(date);
      if (!totalBucketCountsPerDate[bucket]) {
        totalBucketCountsPerDate[bucket] = {};
      }

      const assignmentCountsForDate: number[] = Object.values(
        assignments as IAssignmentsCount,
      );
      assignmentCountsForDate.forEach(count => {
        if (!totalBucketCountsPerDate[bucket][date]) {
          totalBucketCountsPerDate[bucket][date] = 0;
        }
        totalBucketCountsPerDate[bucket][date] += count;
      });
    });
  });

  return allBucketNames.map(bucket => {
    const bucketData: IBucketAssignmentCountData = {
      bucket,
      assignmentCounts: {},
    };
    allDates.forEach(date => {
      bucketData.assignmentCounts[date] =
        totalBucketCountsPerDate[bucket]?.[date] ?? 0;
    });
    return bucketData;
  });
};
