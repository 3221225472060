import { ToCamelCase } from '@heidi-pay/heidi-common-fe/types';
import { addPlaceholderForEmptyValues } from '@heidi-pay/heidi-common-fe/utils';
import { objectKeysToCamelCase } from '@heidi-pay/heidi-common-fe/utils/objectKeysToCamelCase';
import { IDataRow } from '@components/DataDisplay/DataDisplay';
import { Translate } from '@hooks/useI18n';
import { MerchantLogo } from '@pages/MerchantDetails/MerchantLogo';
import { IOpsPortalIban } from '@schemas/opsPortal/types/iban';
import {
  IOpsPortalMerchantDetails,
  IOrderProfileGroup,
} from '@schemas/opsPortal/types/merchantDetails';

interface IGetOrderProfileData extends Partial<IOpsPortalIban> {
  data: IOpsPortalMerchantDetails;
  translate: Translate;
}

interface IGetOrderProfileGroupDataList {
  isHeylightOverwriteByMerchantBrandingEnabled?: boolean;
  orderProfileGroup: ToCamelCase<IOrderProfileGroup>;
  translate: Translate;
}

const getOrderProfileGroupDataList = ({
  isHeylightOverwriteByMerchantBrandingEnabled,
  orderProfileGroup,
  translate,
}: IGetOrderProfileGroupDataList) => {
  const {
    availableOn,
    checkoutLinkDeliveryChannels,
    logo,
    orderProfileRouting,
    status,
    uuid,
  } = orderProfileGroup;

  // for order profiles, just show blank unless merchant branding is enabled
  const merchantLogo = isHeylightOverwriteByMerchantBrandingEnabled
    ? logo
    : null;

  return [
    { key: translate('TITLE.UUID'), value: uuid },
    { key: translate('TITLE.STATUS'), value: status },
    {
      key: translate('OPS_PORTAL.LABEL.AVAILABLE_ON'),
      value: availableOn,
    },
    {
      key: translate('OPS_PORTAL.LABEL.ORDER_PROFILE_ROUTING'),
      value: orderProfileRouting,
    },
    {
      key: translate('OPS_PORTAL.LABEL.CHECKOUT_DELIVERY_CHANNELS'),
      value: checkoutLinkDeliveryChannels.join(', '),
    },
    {
      key: translate('OPS_PORTAL.LABEL.LOGO'),
      value: <MerchantLogo logo={merchantLogo} />,
    },
  ].map(val => addPlaceholderForEmptyValues<IDataRow>(val));
};

export const getOrderProfileData = ({
  data,
  translate,
}: IGetOrderProfileData) => {
  const { merchantSettingGroup, orderProfileGroups } =
    objectKeysToCamelCase(data);
  const { isHeylightOverwriteByMerchantBrandingEnabled = false } =
    merchantSettingGroup ?? {};

  return {
    orderProfileGroups: orderProfileGroups.map(orderProfileGroup => ({
      name: orderProfileGroup.name,
      uuid: orderProfileGroup.uuid,
      dataList: getOrderProfileGroupDataList({
        orderProfileGroup,
        isHeylightOverwriteByMerchantBrandingEnabled,
        translate,
      }),
      orderProfiles: orderProfileGroup.orderProfiles,
    })),
  };
};
