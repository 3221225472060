import { useMutation } from '@tanstack/react-query';
import { ITableParams } from '@hooks/useQueryState/utils/types';
import { Endpoints } from '@utils/enums';
import { fetchDataFromEndpointCsv } from '@utils/fetchDataFromEndpoint';

interface IUseGetExperianIncomeDocumentReportCsv {
  queryParams: ITableParams;
}

export const useGetExperianIncomeDocumentReportCsv = ({
  queryParams,
}: IUseGetExperianIncomeDocumentReportCsv) => {
  const { isLoading: isLoadingCsv, mutate: downloadCsv } = useMutation(() =>
    fetchDataFromEndpointCsv(
      Endpoints.FetchApplicationsExperianIncomeDocumentReportCsv,
      {
        query: queryParams,
      },
      'Experian Income Document Report',
      true,
      {},
    ),
  );

  return {
    isLoadingCsv,
    downloadCsv,
  };
};
