import { useCallback } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { styled } from '@mui/material';
import { TextBoxRHF } from '@components/TextBox';
import { useI18n } from '@hooks/useI18n';
import { useModal } from '@hooks/useModal';
import { getModalDetails } from '@hooks/useModal/getModalDetails';
import { useCreateMerchantNoteQuery } from '@pages/MerchantDetails/queries/useCreateMerchantNoteQuery';
import { IUseAddMerchantNoteModal } from '@pages/MerchantDetails/types';

const ModalTextBox = styled(TextBoxRHF)({
  minHeight: '100px',
});

export const useAddMerchantNoteModal = (props: IUseAddMerchantNoteModal) => {
  const { translate } = useI18n();
  const formID = 'addMerchantNote';
  const { uuid } = props;

  const modal = useModal({
    sharedProps: props,
    buttonTranslationKey: 'OPS_PORTAL.TITLE.ADD_NOTE',
    modalName: 'addMerchantNotes',
    permissions: ['ops_portal.can_view_merchant_notes'],
  });

  const { closeModal } = modal;

  const { createMerchantNote, createMerchantNoteLoading } =
    useCreateMerchantNoteQuery({ closeModal, uuid });

  const schema = Yup.object().shape({
    note: Yup.string().required(translate('VALIDATION.REQUIRED')),
  });

  const methods = useForm({
    mode: 'onChange',
    resolver: yupResolver(schema),
  });

  const handleAddMerchantNote = useCallback(
    data => createMerchantNote(data),
    [createMerchantNote],
  );

  const modalProps = {
    allowoverflow: true,
    modalContent: (
      <FormProvider {...methods}>
        <form
          id={formID}
          onSubmit={methods.handleSubmit(handleAddMerchantNote)}
        >
          <ModalTextBox
            id="note"
            label={translate('OPS_PORTAL.TITLE.MESSAGE')}
            name="note"
          />
        </form>
      </FormProvider>
    ),
    submitText: translate('BUTTON.SUBMIT'),
    title: translate('OPS_PORTAL.TITLE.ADD_NOTE'),
    formID,
    isLoading: createMerchantNoteLoading,
  };

  return getModalDetails({ modal, modalProps });
};
