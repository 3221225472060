import { ChartDataset, ScriptableLineSegmentContext } from 'chart.js';
import { Translate } from '@hooks/useI18n';
import { getChartColourPalette } from '@utils/getChartColourPalette';
import { IPaymentChartData } from '../types';

const getFormattedDatasets = (datasets: ChartDataset<'line'>[]) => {
  const colors = getChartColourPalette(datasets.length);

  return datasets.map((dataset, index) => ({
    ...dataset,
    borderColor: colors[index % colors.length],
    backgroundColor: colors[index % colors.length],
    borderWidth: 2,
    pointRadius: 2,
    fill: false,
  }));
};

interface IGetChartData {
  paymentData: IPaymentChartData[];
  translate: Translate;
}

export const getPaymentChartData = ({
  paymentData,
  translate,
}: IGetChartData) => {
  const chartLabels = paymentData.map(data => data.date);
  const datasetTotalDueToDate = paymentData.map(data => ({
    x: new Date(data.date).getTime(),
    y: data.totalDueToDate,
  }));
  const datasetTotalPaidToDate = paymentData
    .filter(data => new Date(data.date) < new Date())
    .map(data => ({
      x: new Date(data.date).getTime(),
      y: data.totalPaidToDate,
    }));

  // we want to show a dashed line for any data points in the future
  const isSegmentBorderDashed = (
    ctx: ScriptableLineSegmentContext,
    value: number[],
  ) => (ctx.p1DataIndex >= datasetTotalPaidToDate.length ? value : undefined);

  const datasets: ChartDataset<'line'>[] = [
    {
      label: translate('OPS_PORTAL.LABEL.TOTAL_PAID'),
      data: datasetTotalPaidToDate,
    },
    {
      label: translate('OPS_PORTAL.LABEL.TOTAL_DUE'),
      data: datasetTotalDueToDate,
      segment: {
        borderDash: ctx => isSegmentBorderDashed(ctx, [6, 6]) || [6, 0],
      },
    },
  ];

  return {
    labels: chartLabels,
    datasets: getFormattedDatasets(datasets),
  };
};
