import { CrabbStatus } from '../enums';

interface IGetActionLabel {
  created?: boolean;
  success?: boolean;
}
export const getActionLabel = ({
  created,
  success,
}: IGetActionLabel): CrabbStatus => {
  if (success === undefined || created === undefined) {
    return CrabbStatus.UNKNOWN;
  }
  if (success && created) {
    return CrabbStatus.CREATED;
  }
  if (success && !created) {
    return CrabbStatus.UPDATED;
  }
  return CrabbStatus.FAILED;
};
