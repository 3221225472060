import { ChartOptions } from 'chart.js';
import { Translate } from '@hooks/useI18n';

interface IGetPaymentChartOptions {
  currency?: string;
  translate: Translate;
}

export const getPaymentChartOptions = ({
  currency,
  translate,
}: IGetPaymentChartOptions): ChartOptions<'line'> => ({
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    title: {
      display: true,
      text: translate('OPS_PORTAL.TITLE.TOTAL_DUE_AND_TOTAL_PAID_THROUGH_TIME'),
    },
    legend: {
      display: true,
      position: 'bottom',
    },
  },
  scales: {
    x: {
      type: 'time',
      time: {
        unit: 'month',
        tooltipFormat: 'yyyy-MM-dd',
        displayFormats: {
          month: 'MMM yyyy',
        },
      },
    },
    y: {
      title: {
        display: true,
        text: `${translate('LABEL.AMOUNT')} (${currency})`,
      },
    },
  },
});
