import { useParams } from 'react-router-dom';
import { ToCamelCase } from '@heidi-pay/heidi-common-fe/types';
import { addPlaceholderForEmptyValues } from '@heidi-pay/heidi-common-fe/utils';
import { objectKeysToCamelCase } from '@heidi-pay/heidi-common-fe/utils/objectKeysToCamelCase';
import { useCollectionAgencies } from '@hooks/useCollectionAgencies';
import { useI18n } from '@hooks/useI18n';
import { useOriginator } from '@hooks/useOriginator';
import { PaymentLink } from '@pages/ContractDetails/components/PaymentLink';
import {
  useGetCollectionsBlockAutoReferralQuery,
  useGetContractRiskApplicationQuery,
  useGetPausedCommunicationsQuery,
  useGetPaymentLinkQuery,
} from '@pages/ContractDetails/hooks/queries';
import { IContractDetails } from '@schemas/opsPortal/types/contractDetails';
import { IOpsPortalMonetaBalanceDetails } from '@schemas/opsPortal/types/monetaBalanceDetails';
import { IOpsPortalRiskApplicationDetails } from '@schemas/opsPortal/types/riskApplicationDetails';
import {
  ArrearsManagedByKeys,
  CollectionManagementEntities,
} from '@utils/enums';
import { getTranslatedStatus } from '@utils/getTranslatedStatus';
import { IGeneralData } from './types';

interface IGeneralSectionProps {
  contractDetails?: ToCamelCase<IContractDetails>;
  isInArrears: boolean;
  monetaBalanceDetails?: ToCamelCase<IOpsPortalMonetaBalanceDetails>;
  riskApplicationDetails?: ToCamelCase<IOpsPortalRiskApplicationDetails>;
}

export const useGeneralSection = ({
  contractDetails,
  isInArrears,
  monetaBalanceDetails,
}: IGeneralSectionProps) => {
  const { isCompass } = useOriginator();
  const { uuid: externalContractUuid } = useParams();
  const { collectionAgencies } = useCollectionAgencies();

  const isArrearsToBeManagedByHeidi =
    contractDetails?.arrearsManagedBy === ArrearsManagedByKeys.HeidiPay;

  const { collectionsBlockAutoReferralData } =
    useGetCollectionsBlockAutoReferralQuery({
      externalContractUuid,
      isArrearsToBeManagedByHeidi,
      isInArrears,
    });

  const collectionsBlockAutoReferralDetails = objectKeysToCamelCase(
    collectionsBlockAutoReferralData,
  );

  const { getLocaleCurrencyValue, getLocaleDate, translate } = useI18n();

  const { pauseCommunicationsData } = useGetPausedCommunicationsQuery({
    externalContractUuid,
    isInArrears,
  });

  const pauseCommunicationsDetails = objectKeysToCamelCase(
    pauseCommunicationsData,
  );

  const { riskApplicationData } = useGetContractRiskApplicationQuery({
    contractUuid: contractDetails?.contractUuid,
  });

  const riskApplicationDetails = objectKeysToCamelCase(riskApplicationData);

  const { blockExternalCollectionsAutoReferral } =
    collectionsBlockAutoReferralDetails ?? {};

  const { pauseCommunications } = pauseCommunicationsDetails ?? {};

  const { canUsePaymentLink, paymentLinkData } = useGetPaymentLinkQuery({
    contractUuid: contractDetails?.contractUuid,
  });

  const opsPortalPrefix = 'OPS_PORTAL.STATUS';
  const opsPortalPaymentMethodPrefix = 'OPS_PORTAL.PAYMENT_METHOD';

  const collectionsAssignmentManagedBy = contractDetails?.managedByCrabb
    ? CollectionManagementEntities.Crabb
    : CollectionManagementEntities.HeyLight;

  const showAutoReferralStatus =
    isArrearsToBeManagedByHeidi &&
    blockExternalCollectionsAutoReferral !== undefined;

  const data = addPlaceholderForEmptyValues<IGeneralData>({
    contractUuid: contractDetails?.contractUuid,
    arrearsManagedBy:
      contractDetails?.arrearsManagedBy &&
      collectionAgencies[contractDetails?.arrearsManagedBy],
    collectionsAssignmentManagedBy,
    paymentPlanReference: contractDetails?.paymentPlanReference,
    praticaId: contractDetails?.mediobancaExternalId,
    createdDate:
      contractDetails?.createdDate &&
      getLocaleDate({ date: contractDetails?.createdDate }),
    maturityDate:
      monetaBalanceDetails?.maturityDate &&
      getLocaleDate({ date: monetaBalanceDetails?.maturityDate }),
    originalNumberOfInstallments: contractDetails?.originalNumberOfInstallments,
    monthlyPayment: monetaBalanceDetails?.monthlyPaymentAmount
      ? getLocaleCurrencyValue({
          currency: monetaBalanceDetails?.monthlyPaymentAmount.currency,
          value: monetaBalanceDetails?.monthlyPaymentAmount.amount,
        })
      : null,
    currentPhase: riskApplicationDetails?.currentPhase,
    creditStatus:
      riskApplicationDetails?.creditStatus &&
      translate(
        getTranslatedStatus(
          opsPortalPrefix,
          riskApplicationDetails?.creditStatus,
        ),
      ),
    identityStatus:
      riskApplicationDetails?.identityStatus &&
      translate(
        getTranslatedStatus(
          opsPortalPrefix,
          riskApplicationDetails?.identityStatus,
        ),
      ),
    paymentMethodStatus:
      riskApplicationDetails?.paymentMethodStatus &&
      translate(
        getTranslatedStatus(
          opsPortalPrefix,
          riskApplicationDetails?.paymentMethodStatus,
        ),
      ),
    paymentMethod:
      contractDetails?.paymentMethod &&
      translate(
        getTranslatedStatus(
          opsPortalPaymentMethodPrefix,
          contractDetails?.paymentMethod,
        ),
      ),
    paymentLink: <PaymentLink paymentLinkData={paymentLinkData} />,
    externalCollectors: showAutoReferralStatus
      ? translate(
          getTranslatedStatus(
            opsPortalPrefix,
            !blockExternalCollectionsAutoReferral,
          ),
        )
      : null,
    creditType: contractDetails?.creditType,
    automatedEmails:
      pauseCommunications !== undefined
        ? translate(getTranslatedStatus(opsPortalPrefix, pauseCommunications))
        : null,
  });

  const generalSection = [
    {
      label: 'OPS_PORTAL.LABEL.CONTRACT_UUID',
      value: data.contractUuid,
    },
    {
      label: 'OPS_PORTAL.TITLE.ARREARS_MANAGED_BY',
      value: data.arrearsManagedBy,
    },
    {
      label: 'OPS_PORTAL.TITLE.COLLECTIONS_ASSIGNMENT_MANAGED_BY',
      value: data.collectionsAssignmentManagedBy,
    },
    {
      label: 'OPS_PORTAL.LABEL.PAYMENT_PLAN_REFERENCE',
      value: data.paymentPlanReference,
      dependency: !isCompass,
    },

    {
      label: 'OPS_PORTAL.TITLE.PRATICA_ID',
      value: data.praticaId,
      dependency: isCompass,
    },

    {
      label: 'OPS_PORTAL.TITLE.CREATED_DATE',
      value: data.createdDate,
    },
    {
      label: 'OPS_PORTAL.LABEL.MATURITY_DATE',
      value: data.maturityDate,
    },
    {
      label: 'OPS_PORTAL.LABEL.ORIGINAL_NUMBER_OF_INSTALMENTS',
      value: data.originalNumberOfInstallments,
    },
    {
      label: 'OPS_PORTAL.LABEL.MONTHLY_PAYMENT_AMOUNT',
      value: data.monthlyPayment,
    },
    {
      label: 'OPS_PORTAL.LABEL.CURRENT_PHASE',
      value: data.currentPhase,
    },
    {
      label: 'OPS_PORTAL.LABEL.CREDIT_STATUS',
      value: data.creditStatus,
    },
    {
      label: 'OPS_PORTAL.LABEL.IDENTITY_STATUS',
      value: data.identityStatus,
    },
    {
      label: 'OPS_PORTAL.LABEL.PAYMENT_METHOD_STATUS',
      value: data.paymentMethodStatus,
    },
    {
      label: 'OPS_PORTAL.LABEL.PAYMENT_METHOD',
      value: data.paymentMethod,
    },
    {
      label: 'OPS_PORTAL.LABEL.PAYMENT_LINK',
      component: data.paymentLink,
      dependency: Boolean(canUsePaymentLink),
    },
    {
      label: 'OPS_PORTAL.LABEL.CAN_BE_REFERRED_TO_EXTERNAL_COLLECTORS',
      value: data.externalCollectors,
      dependency: showAutoReferralStatus,
    },
    {
      label: 'OPS_PORTAL.LABEL.CREDIT_TYPE',
      value: data.creditType,
    },
    {
      label: 'OPS_PORTAL.LABEL.AUTOMATED_COLLECTIONS_EMAILS_STOPPED',
      value: data.automatedEmails,
      dependency: pauseCommunications !== undefined,
    },
  ].filter(field => field.dependency !== false);

  return { generalSection };
};
