import { Nullable } from '@heidi-pay/heidi-common-fe/types';
import { extractFloatFromString } from '@utils/extractFloatFromString';
import { sort } from '@utils/sort';

export const sortCurrencyString = (
  valA: Nullable<string>,
  valB: Nullable<string>,
) => {
  const floatA = extractFloatFromString(String(valA));
  const floatB = extractFloatFromString(String(valB));

  return sort(floatA, floatB);
};
