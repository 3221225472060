import React from 'react';
import { Box, styled, Typography } from '@mui/material';
import { useI18n } from '@hooks/useI18n';
import { DetailItem } from './DetailsItem';

interface ISectionProps {
  items: {
    component?: JSX.Element;
    label: string;
    value?: string | number | null;
  }[];
  titleKey: string;
}

const Container = styled(Box)({
  width: '100%',
  height: '100%',
});

const StyledDL = styled('dl')(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: '1fr 1fr',
  gap: theme.spacing(1),
  marginBottom: theme.spacing(2),
  border: `1px solid ${theme.palette.divider}`,
  padding: theme.spacing(2),
}));

export const Section = ({ items, titleKey }: ISectionProps) => {
  const { translate } = useI18n();
  return (
    <Container>
      <Typography mb={1} variant="h6">
        {translate(titleKey)}
      </Typography>
      <StyledDL>
        {items.map(({ component, label, value }) => (
          <DetailItem
            component={component}
            key={value}
            label={translate(label)}
            value={value}
          />
        ))}
      </StyledDL>
    </Container>
  );
};

export default Section;
