/* eslint-disable max-len */
import { AxiosError } from 'axios';
import { useQuery } from '@tanstack/react-query';
import { IOpsPortalConsumerCommunicationsEventsByCommunicationTrigger } from '@schemas/opsPortal/types/consumerCommunicationsEventsByCommunicationTrigger';
import { QueryKeys } from '@utils/constants';
import { Endpoints } from '@utils/enums';
import { fetchDataFromEndpoint } from '@utils/fetchDataFromEndpoint';

interface IUseGetConsumerCommunicationsByTriggerQuery {
  communicationTrigger: string;
  consumerUuid?: string;
  contractUuid?: string;
}

export const useGetConsumerCommunicationsByTriggerQuery = ({
  communicationTrigger,
  consumerUuid,
  contractUuid,
}: IUseGetConsumerCommunicationsByTriggerQuery) => {
  const {
    data: consumerCommunicationsByTriggerData,
    error: consumerCommunicationsByTriggerError,
    isLoading: consumerCommunicationsByTriggerIsLoading,
  } = useQuery<
    IOpsPortalConsumerCommunicationsEventsByCommunicationTrigger,
    AxiosError
  >(
    [QueryKeys.COMMUNICATIONS_EVENTS, consumerUuid, contractUuid],
    () =>
      fetchDataFromEndpoint(
        `${Endpoints.FetchConsumerEventsByTrigger}/${consumerUuid}`,
        {
          query: {
            contractUuid: contractUuid ?? '',
            communicationTrigger,
          },
        },
      ),
    {
      enabled: Boolean(contractUuid && consumerUuid),
    },
  );

  return {
    consumerCommunicationsByTriggerError,
    consumerCommunicationsByTriggerData,
    consumerCommunicationsByTriggerIsLoading,
  };
};
