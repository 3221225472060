import { ToCamelCase } from '@heidi-pay/heidi-common-fe/types';
import { addPlaceholderForEmptyValues } from '@heidi-pay/heidi-common-fe/utils';
import { useI18n } from '@hooks/useI18n';
import { IContractDetails } from '@schemas/opsPortal/types/contractDetails';
import { IOpsPortalMonetaBalanceDetails } from '@schemas/opsPortal/types/monetaBalanceDetails';
import { getSystemRemainder } from '@utils/getSystemRemainder';
import { IPaymentsData } from './types';

interface IDetailsSectionProps {
  contractDetails?: ToCamelCase<IContractDetails>;
  monetaBalanceDetails?: ToCamelCase<IOpsPortalMonetaBalanceDetails>;
}

export const usePaymentsSection = ({
  contractDetails,
  monetaBalanceDetails,
}: IDetailsSectionProps) => {
  const { getLocaleCurrencyValue } = useI18n();

  const {
    amount,
    currentOrderAmount,
    totalBalance,
    totalInterest,
    writeOffAmount,
  } = contractDetails ?? {};

  const { totalContractMovement, totalCustomerRefund, totalPaid } =
    monetaBalanceDetails ?? {};

  const systemRemainder = getSystemRemainder(
    totalBalance,
    totalContractMovement,
  );

  const totalInterestValue =
    Number(totalInterest?.amount) > 0
      ? getLocaleCurrencyValue({
          currency: totalInterest?.currency,
          value: totalInterest?.amount,
        })
      : null;

  const totalAmountValue = totalInterestValue
    ? getLocaleCurrencyValue({
        currency: amount?.currency,
        value: (
          Number(amount?.amount) + Number(totalInterest?.amount)
        ).toString(),
      })
    : null;

  const data = addPlaceholderForEmptyValues<IPaymentsData>({
    originalAmount: amount
      ? getLocaleCurrencyValue({
          currency: amount.currency,
          value: amount.amount,
        })
      : null,
    totalInterest: totalInterestValue,
    totalAmount: totalAmountValue,
    currentAmount: currentOrderAmount
      ? getLocaleCurrencyValue({
          currency: currentOrderAmount.currency,
          value: currentOrderAmount.amount,
        })
      : null,
    amountRefunded: systemRemainder
      ? getLocaleCurrencyValue({
          currency: systemRemainder.currency,
          value: systemRemainder.amount,
        })
      : null,
    amountReimbursed: totalCustomerRefund
      ? getLocaleCurrencyValue({
          currency: totalCustomerRefund.currency,
          value: totalCustomerRefund.amount,
        })
      : null,
    amountPaid: totalPaid?.amount
      ? getLocaleCurrencyValue({
          currency: totalPaid.currency,
          value: totalPaid.amount,
        })
      : null,
    amountWrittenOff: writeOffAmount
      ? getLocaleCurrencyValue({
          currency: writeOffAmount.currency,
          value: writeOffAmount.amount,
        })
      : null,
  });

  const paymentsSection = [
    {
      label: 'OPS_PORTAL.LABEL.ORIGINAL_AMOUNT',
      value: data.originalAmount,
    },
    {
      label: 'OPS_PORTAL.LABEL.TOTAL_INTEREST',
      value: data.totalInterest,
      dependency: !!totalInterestValue,
    },
    {
      label: 'OPS_PORTAL.LABEL.TOTAL_AMOUNT',
      value: totalAmountValue,
      dependency: !!totalAmountValue,
    },
    {
      label: 'OPS_PORTAL.LABEL.CURRENT_AMOUNT',
      value: data.currentAmount,
    },
    {
      label: 'OPS_PORTAL.LABEL.AMOUNT_REFUNDED',
      value: data.amountReimbursed,
    },
    {
      label: 'OPS_PORTAL.LABEL.AMOUNT_REIMBURSED_TO_CUSTOMER',
      value: data.amountRefunded,
    },
    {
      label: 'OPS_PORTAL.LABEL.AMOUNT_PAID',
      value: data.amountPaid,
    },
    {
      label: 'OPS_PORTAL.LABEL.AMOUNT_WRITTEN_OFF',
      value: data.amountWrittenOff,
    },
  ].filter(field => field.dependency !== false);

  return { paymentsSection };
};
