import { AxiosError } from 'axios';
import { IError } from '@heidi-pay/heidi-common-fe/utils/errorHandler/types';
import { useMutation } from '@tanstack/react-query';
import { usePortalError } from '@hooks/usePortalError/usePortalError';
import { IAttachment } from '@schemas/opsPortal/types/consumerCommunicationEvent';
import { downloadFileFromBlob } from '@utils/downloadCSV/downloadFileFromBlob/downloadFileFromBlob';
import { Endpoints } from '@utils/enums';
import { fetchDataFromEndpoint } from '@utils/fetchDataFromEndpoint';

export const useDownloadAttachmentQuery = (uuid?: string) => {
  const { handleAxiosError } = usePortalError();

  const { mutate: handleAttachmentDownload } = useMutation<
    ArrayBuffer,
    AxiosError<IError>,
    IAttachment
  >(
    attachment =>
      fetchDataFromEndpoint(
        `${
          Endpoints.DownloadCommunicationAttachment
        }/${uuid}/${encodeURIComponent(attachment.filename)}`,
        {
          axiosArgs: {
            responseType: 'blob',
          },
        },
      ),
    {
      onSuccess: (response, variables) => {
        downloadFileFromBlob({
          filename: variables.filename,
          mimetype: variables.mimetype,
          data: response,
        });
      },
      onError: error => {
        handleAxiosError(error, 'ERROR.FILE_DOWNLOAD');
      },
    },
  );

  return handleAttachmentDownload;
};
