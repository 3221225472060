import { ChangeEvent, useCallback } from 'react';
import { Switch, FormControlLabel, styled } from '@mui/material';
import { HeyLightBranding } from '@utils/constants';

interface IToggle {
  handleSwitch: (switchValue: boolean) => void;
  isOn: boolean;
  label?: string;
}

const SwitchStyled = styled(Switch)({
  '& .MuiSwitch-switchBase.Mui-checked': {
    color: HeyLightBranding.primaryColour,
  },
  '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
    backgroundColor: HeyLightBranding.primaryColour,
  },
});

export const Toggle = ({ handleSwitch, isOn, label }: IToggle) => {
  const onChangeHandler = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      handleSwitch(event.target.checked);
    },
    [handleSwitch],
  );

  return (
    <FormControlLabel
      control={<SwitchStyled checked={isOn} onChange={onChangeHandler} />}
      label={label}
    />
  );
};
