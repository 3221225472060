import { useI18n } from '@hooks/useI18n/useI18n';
import { useLazyLoadTab } from '@hooks/useLazyLoadTab/useLazyLoadTab';
import { IOpsPortalMonetaContract } from '@schemas/opsPortal/types/monetaContract';
import { TabEventKeys, TableNames } from '@utils/constants';
import { PaymentsChart } from './PaymentsChart/PaymentsChart';
import { PaymentsTable } from './PaymentsTable/PaymentsTable';

export const useContractPaymentsTab = (
  monetaContractData?: IOpsPortalMonetaContract,
) => {
  const { translate } = useI18n();
  const { hasTabBeenClicked } = useLazyLoadTab({
    eventKey: TabEventKeys.CONTRACT_PAYMENTS,
  });

  return {
    component: (
      <>
        <PaymentsTable monetaContractData={monetaContractData} />
        {hasTabBeenClicked ? (
          <PaymentsChart monetaContractData={monetaContractData} />
        ) : null}
      </>
    ),
    eventKey: TabEventKeys.CONTRACT_PAYMENTS,
    title: translate('TITLE.PAYMENTS'),
    tableName: TableNames.PAYMENTS,
  };
};
