import { ToCamelCase } from '@heidi-pay/heidi-common-fe/types';
import { addPlaceholderForEmptyValues } from '@heidi-pay/heidi-common-fe/utils';
import { useI18n } from '@hooks/useI18n';
import { getFormattedLocaleDate } from '@pages/ContractDetails/utils/getFormattedLocaleDate';
import { IOpsPortalCollectionsDetails } from '@schemas/opsPortal/types/collectionsDetails';
import { IContractDetails } from '@schemas/opsPortal/types/contractDetails';
import { IOpsPortalMonetaBalanceDetails } from '@schemas/opsPortal/types/monetaBalanceDetails';
import { getTranslatedData } from '@utils/getTranslatedData';
import { IPerformanceData } from './types';

interface IPeformanceSectionProps {
  collectionsDetails?: ToCamelCase<IOpsPortalCollectionsDetails>;
  contractDetails?: ToCamelCase<IContractDetails>;
  monetaBalanceDetails?: ToCamelCase<IOpsPortalMonetaBalanceDetails>;
}

export const usePerformanceSection = ({
  collectionsDetails,
  contractDetails,
  monetaBalanceDetails,
}: IPeformanceSectionProps) => {
  const { getLocaleCurrencyValue, getLocaleDate, translate } = useI18n();

  const {
    originalNumberOfInstallments,
    performanceStatus,
    status,
    terminationDate,
  } = contractDetails ?? {};

  const { monthlyPaymentAmount, totalPaid } = monetaBalanceDetails ?? {};

  const {
    arrearsAmount,
    bucket,
    daysInArrears,
    daysInArrearsByReconciliationDate,
    oldestDueDate,
    paymentsDown,
  } = collectionsDetails ?? {};

  const data = addPlaceholderForEmptyValues<IPerformanceData>({
    activityStatus: status
      ? getTranslatedData({ term: status, translate })
      : null,
    terminationDate: terminationDate
      ? getFormattedLocaleDate(terminationDate, getLocaleDate)
      : null,
    performanceStatus: performanceStatus
      ? getTranslatedData({ term: performanceStatus, translate })
      : null,
    originalNumberOfInstallments,
    monthlyPaymentAmount:
      monthlyPaymentAmount &&
      getLocaleCurrencyValue({
        currency: monthlyPaymentAmount.currency,
        value: monthlyPaymentAmount.amount,
      }),
    totalPaid:
      totalPaid &&
      getLocaleCurrencyValue({
        value: totalPaid.amount,
        currency: totalPaid.currency,
      }),
    paymentDueDate: oldestDueDate && getLocaleDate({ date: oldestDueDate }),
    daysInArrears,
    daysInArrearsByReconciliationDate,
    paymentsDown,
    bucket: bucket?.bucketId,
    arrearsAmount:
      arrearsAmount?.amount &&
      getLocaleCurrencyValue({
        currency: arrearsAmount.currency,
        value: arrearsAmount.amount,
      }),
  });

  const performanceSection = [
    {
      label: 'OPS_PORTAL.LABEL.ACTIVITY_STATUS',
      value: data.activityStatus,
    },
    {
      label: 'OPS_PORTAL.LABEL.TERMINATION_DATE',
      value: data.terminationDate,
    },
    {
      label: 'OPS_PORTAL.LABEL.PERFORMANCE_STATUS',
      value: data.performanceStatus,
    },
    {
      label: 'LABEL.NUMBER_OF_INSTALMENTS',
      value: data.originalNumberOfInstallments,
    },
    {
      label: 'OPS_PORTAL.LABEL.MONTHLY_PAYMENT_AMOUNT',
      value: data.monthlyPaymentAmount,
    },
    {
      label: 'OPS_PORTAL.LABEL.AMOUNT_PAID',
      value: data.totalPaid,
    },
    {
      label: 'OPS_PORTAL.LABEL.CURRENT_ARREARS_DAY_ZERO',
      value: data.paymentDueDate,
    },
    { label: 'OPS_PORTAL.LABEL.DAYS_IN_ARREARS', value: data.daysInArrears },
    {
      label: 'OPS_PORTAL.LABEL.DAYS_IN_ARREAR_BY_RECONCILIATION_DATE',
      value: data.daysInArrearsByReconciliationDate,
    },
    {
      label: 'OPS_PORTAL.LABEL.PAYMENTS_DOWN',
      value: data.paymentsDown,
    },
    {
      label: 'OPS_PORTAL.LABEL.BUCKET',
      value: data.bucket,
    },
    {
      label: 'LABEL.ARREARS_AMOUNT',
      value: data.arrearsAmount,
    },
  ];

  return { performanceSection };
};
