import { H3 } from '@components/Common.styles';
import { ClientTableV2 } from '@components/TableV2/ClientTableV2';
import { useI18n } from '@hooks/useI18n/useI18n';
import { IOpsPortalMonetaContract } from '@schemas/opsPortal/types/monetaContract';
import { TableNames } from '@utils/constants';
import { getPaymentsTableColumns } from './getPaymentsTableColumns';
import { mapPaymentsData } from './mapPaymentsData';

interface IPaymentsTable {
  monetaContractData?: IOpsPortalMonetaContract;
}

export const PaymentsTable = ({ monetaContractData }: IPaymentsTable) => {
  const { getLocaleCurrencyValue, getLocaleDate, translate } = useI18n();

  const contractPaymentsColumns = getPaymentsTableColumns(translate);

  const contractPaymentsData = mapPaymentsData({
    data: monetaContractData,
    getLocaleCurrencyValue,
    getLocaleDate,
    translate,
  });

  return (
    <>
      <H3>{translate('OPS_PORTAL.TITLE.PAYMENTS')}</H3>
      <ClientTableV2
        columns={contractPaymentsColumns}
        data={contractPaymentsData}
        minWidth="1000px"
        name={TableNames.PAYMENTS}
        wrapTableInCard={false}
      />
    </>
  );
};
